.savedreflection_outer {
    background: #D7DAD7;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;
}

.userimage {
    display: flex;
    align-items: center;
}

.user_detail {
    padding-left: 10px;
    margin-top: 10px;
}
.user_detail p{
 color: #404240;;
font-weight: 500;
letter-spacing: -0.03em;
}
.fill_survey_btn{
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 22px;
color: #ffffff;
border-radius: 8px;
padding: 9px 18px;
border: transparent;
}
.user_paragraph{
background: #F9F9F7;
border: 1px solid #D7DAD7;
border-radius: 6px;
padding: 15px 16px;
margin-top: 10px;
}
.user_paragraph p{
letter-spacing: -0.03em;
color: #404240;
}
.user_paragraph h5{
    color: #9C9F9C;
}
.individual_saved_reflection{
background: #E4E5E1;
border-radius: 4px;
padding: 20px;
margin-top: 10px;
margin-bottom: 10px;
}
.saved_reflection_heading{
 font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 11px;
letter-spacing: -0.03em;
color: #171919;


}
.user_detail h2{
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 38px;
letter-spacing: -0.03em;
color: #171919;
}
.reflection_container{
    display: flex;
    justify-content: space-between;
}
.userimage img{
    height: 80px ;
    width: 80px;
}
.maximise_arrow_img{
    height: 20px;
    width: 20px;
}
@media screen and (max-width:767px){
    .userimage img{
        height: 50px ;
        width: 50px;
    }
    .savedreflection_outer{
        display: block;
        text-align: center;
    }
    .userimage{
        display: block;
    }
    .user_detail h2{
        font-size: 20px;
    }
    .saved_reflection_heading {
     font-size: 16px;   
    }
    .maximise_arrow_img {
        height: 16px;
        width: 16px;
    }
}