.surveyname_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 27px 17px 0px;
    background: #E1DAD8;
    border-radius: 8px;
}
.cmn_surveyName_btn{
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 68px;
    height: 40px;
}
.report_outer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.edit_icon {
    height: 15px;
    width: 15px;
}

.edit_img_outer {
    height: 30px;
    width: 30px;
    background: #E6E9EC;
    border: 1px solid #D1D9E2;
    border-radius: 5px;
    padding: 0px 8px;
}
.surveyName_delete_btn{
    color: #5F6D7E;

    background: #F3F2EF;
    border: 1px solid #D1D9E2;
    margin-right: 10px;
}
.surveyName_save_btn{
    color: #F8F9FB;
    border: 1px solid #D1D9E2;
}
.satisfied_text {
    background: #F8F9FB;
    border: 1px solid #D1D9E2;
    border-radius: 8px;
    padding: 13px 18px;
    display: flex;
    margin-top: 16px;
    align-items: center;


}

.userFeedback_outer {
    padding: 1px 25px;
    background: #F8F9FB;
    border: 1px solid #D1D9E2;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
    margin-top: 15px;

}
.label_satisfied{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #5F6D7E;
    padding-left: 8px;
    margin-bottom: 0px;
}
.satisfied_wrapper{
    margin-top: 40px;
}
.satisfied_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #2E3646;
}
.form-check-input:checked {
    /*background: #2E3646;*/
    border:  #2E3646;;
}
.experience_input_field{
    margin-top: 10px;
}
.survey_name_Heading h2{

    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.01em;
    color: #2E3646;
}
.survey_name_Heading p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.001em;
    color: #5F6D7E;

}
.experience_Form input::placeholder {
    color: #7C8B9D;

}

.experience_Form{
    padding-top: 12px;
}

@media screen and (max-width:991px){
    .satisfied_heading {
        font-size: 16px;
    }
    .survey_name_Heading h2{
        font-size: 21px;
    }
}
@media screen and (max-width:767px){
    .survey_name_Heading h2{
        font-size: 16px;
    }
    .survey_name_Heading{
        margin: 0px;
    }
    .surveyname_wrapper {
        text-align: center;
        padding: 27px 10px 0px;
        display: block;
    }
    .satisfied_heading {
        font-size: 12px;
    }
    .label_satisfied {
        font-size: 12px;
    }
}