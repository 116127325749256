.opacity5{
    opacity: .5;
}
.opacity6{
    opacity: .6;
}
.team-report-loader{
    text-align: center;
    font-size: 25px;
    padding-top: 70px;
    padding-bottom: 90px;
}
.no-report-available {
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #aaaaaa5c;
    font-family: 'General Sans Medium';
}
.toggle-view-report{
    margin-bottom: -20px;
}
.team-report-table{
    margin-left: 14px;
}
.team-report-loader span.spinner-border {
    height: 60px;
    width: 60px;
    color: #b6b4b4;
}
.team-report-info {
    margin-top: 5px;
    text-align: center;
    color: red;
}