.pro-sidebar {
    /*background: rgba(243, 242, 239, 1) !important;*/
    background: #F5F5F5 !important;
    color: rgb(10 9 9 / 97%) !important;


}

span.general-data {
    margin-left: 12px;
    font-size: 13px;
    font-weight: 500;
    color: #9599A1;
    padding-top: 20px;
    display: inline-block;
}
.pro-sidebar .head-div {
    display: none;
}

.pro-menu ul {
    padding: 0;
    margin: 0;
}

.pro-menu ul a {
    color: black;
    position: relative;
}

.pro-menu ul a li {
    list-style: none;
   margin: 4px 0px;
    padding: 11.67px;
    border-radius: 10px;
}

.pro-sidebar .head-div {
    display: none !important;
}

.eslRuw a {
    color: rgb(0, 0, 0) !important;
    text-decoration: none;
}

.menuItem {
    display: grid;
    width: 100%;
}

.menuItem a {
    padding: 15px;
    margin-left: 12px
}

.pro-sidebar {
    position: fixed !important;
    top: 0px;
    z-index: 2 !important;
   padding: 12px;

}

.pro-sidebar-footer {
    position: absolute;
    width: 100%;
    display: block;
    left: 32px;
    bottom: 54px;
}
.pro-menu ul li div {
    padding: 0;
    height: unset;
}


.icon_img span {
    margin-left: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #5E5D62;
}

.pro-sidebar-footer span {
    margin-left: 9px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    cursor: pointer;
}


.poweby {
    margin-left: 43px;
    margin-top: 5px;


}
.active-color{
    color: #f8552b;
}
.Pulse_logo_img {
    width: 58px;
    margin-left: 12px;
    opacity: 0.2;
}

.powered_by_img {
    width: 80px;
}
.customClass{
    background: white;
}

a.active li {
    border-radius: 10px;
    background: linear-gradient(180deg, #FF7E42 0%, #FC6620 100%);
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.25) inset, 0px 2px 2px 0px rgba(132, 45, 5, 0.15);
}
.sidebar-icons {
    height: 15px;
    width: 15px;
    margin-bottom: 2px;
    color: #9599A1;
}
.campaign-icon{
    height:20px;
    width:20px;
}
a.active .icon_img span, a.active i,a.active svg {
    color: #fff;

}
a.active img{
    filter: brightness(0) invert(1);
}
.deafualt_color a.active li {
    color: #fff;
    background: #180280;
}

.deafualt_color .bar {
    background: #180280;
}
.bar {
    position: absolute;
    width: 5px;
    height: 44px;
    top: 4px;
    background: #f8552b;
    border-radius: 0 40px 40px 0;
}
div.sidebar{
    width: 240px;
    min-width: unset;
}
.hamburger {
    display: block !important;
    min-width: 270px !important;
    width: 270px !important;
    transition: width 0s ease 0s, left 0s ease 0s, right 0s ease 0s, all 0.3s ease 0s !important;
}
@media screen and (max-width: 767px) {
    div .pro-sidebar{
        width: 80px ;
        min-width: 80px;
    }
    .pro-sidebar.sidebar.hamburger{
        margin-left: 0px;
        box-shadow: 1px 1px 20px 10px #8080801c;
        transition: ease all .5s;
        -webkit-transition: ease all .5s;
    }

    .pro-sidebar.sidebar {
        margin-left: -270px;
        transition: ease all .5s;
        -webkit-transition: ease all .5s;
    }
  

    span.icon-suffix {
        display: block;
        padding-top: 11px;
    }

    .pro-sidebar-footer {
        position: unset;
    }

    .sidebar_data {
        margin-left: 29px !important;
    }

    span.item-content {
        display: unset !important;
    }

    .sc-dQelHR.pro-sidebar.pro-sidebar.toggled {
        min-width: 62px;
        width: 62px;
    }

    .sc-gUrTyy.kkqKLc.toggled {
        padding: 0;
    }

    .user_stuff {
        display: none;
    }

    .user_info {
        margin-left: auto;
        padding-right: 20px;
    }

    .sc-kiLEMZ.gQjtXM {
        display: block;
    }
}


@media screen and (max-width: 720px) {
    .bar{
        top: 15px;
    }
    .dashboard-bar{
        top:4px;
    }
}