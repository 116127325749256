.edit_mentee_form {
    background: #F9F9F7;
    border-radius: 4px;
    padding: 15px;
}
.edit_btn {
    padding: 8px;
    background: #e4e4e4;
    border: none;
    color: #404240;
    margin-right: 10px;
    width: 60px;
}
.edit-form-buttons {
    display: flex;
    justify-content: right;
}
.thought-height{
    height: 100%;
}
.attempted-reflection-table {
    margin-top:12px;
}
.attempted-reflection-skeleton-loader{
    width: 100%;
}
.attempted-reflection-skeleton-loader .loader-wrapper .loader-outer {
    /* width: 90%; */
    margin-left: -30px;
}
.attempt_survey_draft span {
    font-family: 'General Sans Regular';
    padding: 4px 6px;
    opacity: unset;
    background: rgb(242 54 43 / 30%);
    border: 1px solid #F8B0AC;
    border-radius: 4px;
    color: #171919;
    font-size: 13px;
    font-weight: 600;
}

.user-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.recommended-info-others h5{
    font-size: 18px;

}
.card-recommended {
    display: flex;
}

.recommended-info {
    width: 50%;
}
.recommended-info-others p span {
    margin-left: 6px;
}
.email-icon {
    font-size: 20px;
}

.phone-icon {
    font-size: 17px;
}
.specialities {
    display: flex;
    flex-wrap: wrap;
}
ul.specialities li {
    font-size: 12px;
    margin-left: 11px;
}
.specialities span{
    margin-left: 10px;
    font-size: 14px;
}

.recommendations{
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.card-recommended img {
    width: 70px;
    height: 70px;
    border-radius: 12px;
    object-fit: cover;
}

.recommended-info h5 {
    font-size: 18px;
}
.recommended-info-others p {
    font-size: 13px;
    margin-top: -9px;
}

.recommended-info-others h6 {
    font-size: 13px;
}
.recommended-info p {
    font-size: 13px;
    margin-left: 11px;
    line-height: 0px;
}
.recommended-info-others h6 span{
    margin-left: 3px;
}
.attempt_survey_completed span {
    font-family: 'General Sans Regular';
    padding: 4px 6px;
    opacity: unset;
    background: rgb(102 229 11 / 30%);
    border: 1px solid #73a151;
    border-radius: 4px;
    color: #171919;
    font-size: 13px;
    font-weight: 600;
}
.pagination_scroll {
    max-height: 628px;
    height: 100%;
    overflow-y: auto;
}
/*new member details page css*/

.other-details .mediumFontSize{
    display: flex;
    align-items: center;
    gap: 5px;
}
.reset_form .add-tag-icon {
    margin-left: 8px;
    font-size: 12px;
}
.reset_form .survey-tags {
    font-size: 14px;
    border-radius: 3px;
    display: inline-block;
    background: #e5e5e5;
    margin: 5px;
    padding: 3px 6px 0px 6px;
}
.reset_form .survey-tag-outer {
    padding: 1px;
    border: 1px solid #d7dad7;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    background: white;
}
.reset_form .add-tag-outer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background: #fff;
}
.other-details span{
    color: #8B8E98;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.other-details .mediumFontSize p {
    color: #000;
    font-size: 14px;
}

.other-details .mediumFontSize span{
    color: #8B8E98;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-left: 3px;
}
.reflection-result {
    display: flex;
}
.member-header {
    display: flex;
    border:1px solid #e2e2e2;
    background: white;
    border-radius: 10px;
    padding: 20px;
    align-items: center
}

.first-Card {
    background: white;
    border-radius: 10px;
    padding: 10px;
    border :1px solid #e2e2e2
}


.Icon-btn {
    margin-left: auto;
    cursor: pointer;
    margin-top: -3px;
    width: 26px;
    height: 25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}
.Icon-btn svg {
    align-items: center;
    margin-top: 6px;
    font-size: 13px;
}
.gruop-data h5 {
    margin-left: 10px;
}

.show_status {
    display: flex;
    align-items: center;
}
.show_status .dot-class-bg-green {
    width: 25px;
    height: 9px;
    background: #019974;
    border-radius: 30px;
}

.show_status .dot-class-bg-red {
    width: 25px;
    height: 9px;
    background: #FF4040;
    border-radius: 30px;
}


.show_status .dot-class-bg-orange {
    width: 25px;
    height: 9px;
    background: #EE892C;
    border-radius: 30px;
}
.member-header  #profile-pic-placeholder {
    width: 60px;
    height: 60px;
}

.other-details {
    margin-left: 10px;
    display: flex;
    gap: 12px;
}
.reflection-result h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.54px;
}
.thoughts_footer {
    display: flex;
    justify-content: space-between;
}

.thoughts_footer input.form-control {
    max-width: 300px;
    width: 300px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    height: 44px;
}
.custom_pagination .pagination-border{
    border: none;
}
.first-Card textarea {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    margin-bottom: 20px;
}
.thought-scroll{
    max-height: 950px;
    overflow-y: scroll;
    height: 100%;
}
.thoughts_card .show_more{
    color: #0D99FF;
    display: inline-block;
    padding-left: 2px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 6px;

}
.add_thouts{
    margin-left: auto;
}
.stat-txt{
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;

}
.graph-heading-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.reflection_color {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}
.edit-info i {
    margin-top: 10px;
    font-size: 19px;
    cursor: pointer;
    color: #FF4040 !important;
}
.thoughts-content p {
    width: 95%;
}
.updated-buttons {
    display: flex;
}

.updated-buttons button {
    border-radius: 4px;
    margin-right: 5px;
    padding: 5px 30px;
    display: flex;
}
.gruop-data svg {
    margin-left: 10px;
    cursor: pointer;
}
.edit-info {
    display: flex;
    margin-left: 11px;
}

.edit-info input {
    margin-right: 10px;
}

.thoughts-content {display: flex;}

.thoughts-content i {
    margin-left: 10px;
    margin-top: 7px;
}
.buttons-reflection {
    margin-top: -20px;
}
.other-details i {
    cursor: pointer;
    font-size: 14px;
    margin-left: 1px;
    color: #000;
}
.assign-Name p:hover {
    color: #3503ee !important;
}
.gruop-data {
    display: flex;
}

.gruop-data .assign-Name {
    margin: auto;
    display: flex;
}
.assign-Name p{
    margin-left: 6px;
    cursor: pointer;
    color: #8B8E98;
}
.reflection-names {
    gap: 20px !important;
    font-size: 12px;
    font-weight: 600;
    margin-right: 28px;
}
button.more_thoughts {
    color: #0D99FF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border: transparent;
    background: transparent;
    text-decoration: underline;
    margin-bottom: 8px;
}

.thoughts_card {
    border-radius: 12px;
    border: 1px solid #F1F1F3;
    padding: 12px;
    margin-bottom: 20px;
}
/*end member details page css*/
.mentee_details{
    background: #ffffff;
    border-radius: 7px;
    border: 1px solid #e2e5e0;
    height: 100%;
}
.thoughts-outer {
    border: 1px solid #e2e5e0;
    border-radius: 7px 7px 0 0;
}
.thoughts-container {
    background: white;
    border-top: 1px solid #e2e5e0;
    padding: 13px;
}
.thoughts {
    /*background: rgba(227, 229, 225, 1);*/
    padding: 10px 10px;
    border-radius: 10px;
}
.thoughts-heading{
    border-radius: 7px 7px 0 0;
    background: #F9F9F7;
    padding: 12px 0px 11px 11px;
    margin: -1px;
    border: 1px solid #e2e5e0;
}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
span.survey-risk-status {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
     width: 100px;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: black;
    padding: 5px 10px;
    margin-left: auto;
    border-radius: 7px;
}


.mentee_form{
    padding: 7px;
}
.cursor{
    cursor: pointer;
}
.cursor:hover{
    color: #0a58ca;
}
.survey-container {
    padding: 7px 0 7px 0;
}
.result-container {
    margin-top: -3px;
    /*padding: 12px;*/
    background: #E4E5E1;
    border-radius: 8px;
}
.member-detail-buttons{
    display: flex;
    justify-content: space-between;
    padding-left: 0;
}
.mentee_form_heading{
    border-radius: 7px 7px 0 0;
    background: white;
    padding: 12px 0px 11px 11px;
    margin: -13px;
    border-bottom: 1px solid #e2e5e0;
    border-top: 1px solid #e2e5e0;
    border-left: 1px solid #e2e5e0;
    border-right: 1px solid #e2e5e0;
}

.member-detail-button{
    display: flex;
    justify-content: space-between;
}
.Results-table-heading{
    border: 1px solid #e2e5e0;

}
.survey_list{
    padding-right: 0px;
}
.members-detail-user-graph-outer{
    padding-left: 0px ;
}
.color-grey{
    color: grey;
}

/*.note_section{*/
/*    background: white !important;*/
/*}*/

/*.search_section{*/
/*    background: #f0efeb !important;*/
/*}*/
.reflections_attempted_table thead tr th:nth-child(2),.reflections_attempted_table thead tr th:nth-child(3),.reflections_table thead tr th:last-child {
    min-width: 125px;
}
.reflections_attempted_table thead tr th:first-child {
    min-width: 150px;
}

.survey-heading{
    border-radius: 7px 7px 0px 0px;
    background: #F9F9F7;
    padding: 12px 0px 11px 11px;
    border-bottom: 1px solid #e2e5e0;
    border-top: 1px solid #e2e5e0;
}
@media screen and (max-width: 1200px) {

    .attempted-reflection-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -12px;
    }
}
@media screen and (max-width: 991px) {
    .attempted-reflection-skeleton-loader .loader-wrapper .loader-outer {
        /* width: 90%; */
        margin-left: -22px;
    }
    .mentee_details{
        margin: auto;

        background: #ffffff;
        border-radius: 7px;
        border: 1px solid #e2e5e0;
        height: 100%;

    }
    .survey_list{
        padding: 0 !important;
    }

}
@media screen and (max-width: 575px) {
    .attempted-reflection-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -5px;
    }
}
@media screen and (max-width: 530px) {
    .member-detail-buttons{
        display: block;
    }
    .promote-role-btn-outer{
        margin-top: 4px;
        text-align: center;
    }
}
@media screen and (max-width: 387px) {
    .update-role-btn{
        margin-bottom: 4px;
    }
}

