
.user_outer {
    background: #F9F9F7;
    border: 1px solid #D7DAD7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
    /* chnages */
    height: 100%;
    cursor: pointer;
}
.assign-icon-disable {
    color: #e2e2e2;
    font-size: 14px;
    pointer-events: none;
}
.custom_roles .css-13cymwt-control {
    height: 45px;
}
.font-wt-500{
    font-weight: 500;
}
.font-wt-600{
    font-weight: 600 !important;
}
.member-name-email-outer.ps-2.wrap-text.font-wt-600:hover {
    color: #007bff !important;
}
.display_profile_data a:hover {
    color: #007bff !important;
}
.members-btn .search-btn {
    border: transparent;
    border-radius: 10px;
    height: 35px;
    font-size: 14px;
    min-width: 53px;
    padding: 8px 12px;
}
.colored_table div[role="rowgroup"] .rdt_TableRow:nth-child(even) {
    background: #F9FAFB;
}
.members-btn .search-btn i {
   color: #ffff;
    margin-right: 7px;
}
.assign-icon {
    font-size: 14px;
    cursor: pointer;
}
.choose-file-button {
    display: flex;
    position: absolute;
    justify-content: center;
    padding-left: 19px;
    padding-top: 7px;
    font-size: 13px;
}

.choose-file-button button {
    border: transparent;
    border-radius: 4px;
    padding: 7px 7px;
    font-size: 13px;
}
.display_profile_data .placeholder-container {
    height: 30px;
    width: 30px;
    min-width: 30px;
}

.member_header {
    display: flex;
    justify-content: space-between;
    /* padding-top: 18px; */

}

.action_buttons {
    display: flex;
    margin-top: 10px;
}

.member_data table thead {
    border-radius: 10px;
    vertical-align: middle;
    background: #F9F9F7;
    border-bottom: 1px solid #D7DAD7;
}

.edit_icon_member {
    width: 26px;
    height: 25px;
    background: rgba(240, 239, 235, 1);
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    margin-right: 15px;
}

.sub-org-members-outer {
    padding: 20px 0px;
}

.inviteStatus span {
    opacity: unset;
    background: rgb(102 229 11 / 30%);
    border: 1px solid #73a151;
    border-radius: 4px;
    color: #171919;
    font-size: 13px;
    font-weight: 600;
}

svg.refrsh {
    font-size: 16px !important;

}

.rotate_icon {
    animation: rotate 1s infinite linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.inviteStatus_padding span {
    opacity: unset;
    background: rgb(225 137 6 / 30%);
    border: 1px solid #FBD9A7;
    border-radius: 4px;
    color: #171919;
    font-size: 13px;
    font-weight: 600;
}


.inviteStatus_disable span {
    opacity: unset;
    background: rgb(242 54 43 / 30%);
    border: 1px solid #F8B0AC;
    border-radius: 4px;
    color: #171919;
    font-size: 13px;
    font-weight: 600;
}

.display_profile_data img {
    width: 30px;
}

.member_data table tbody .action_buttons {
    border-bottom-width: 0;
}

.display_profile_data a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    color: #171919;
    text-decoration: none;
}

.delete_icon_member {
    width: 26px;
    height: 25px;
    background: rgba(240, 239, 235, 1);
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
}

.delete_icon_member svg {
    font-size: 17px;
    margin-top: 5px;
}


.edit_icon_member svg {
    font-size: 14px;
}

.member_data table tbody {
    background-color: #ffff;
}

.member_data table tbody tr {
    border: 1px solid #E3E5E1;
}

.member_data table {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    margin-bottom: 0;
}

.member_data table thead th {
    padding: 13px 17px;

}

.member_data table tbody tr th {
    padding: 13px 17px;
    width: 18%;
}

.members_btns {
    display: flex;
}

.search_div {
    position: relative;
}

.search_div input {
    width: 354px;
    height: 40px;
    border: 1px solid #5F6D7E;
    border-radius: 6px;
    background: #F8F9FB;
    text-indent: 32px;
    color: #5F6D7E;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

}

.cmn_background_color_addmember {
    background: #E6E9EC;
    color: #5F6D7E;
}

.cmn_background_color_survey {
    background: #2E3646;
    color: #F8F9FB;;

}

.members_btns button {
    width: 111px;
    height: 40px;
    border: 1px solid #D1D9E2;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.001em;

    margin-left: 10px;
}

.search_div span {
    position: absolute;
    top: 7px;
    left: 11px;
}

.search_div span img {
    width: 15px;
}

.member_outer {
    padding-top: 18px;
    row-gap: 10px;
}

.main_content.box_design1 .row {
    row-gap: 10px;
}

.actions-members-table {
    display: flex;
    align-items: center;

}

.member_header h2 {

    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #171919;
}

.surveyBtns button {
    width: 98px;
    height: 28px;
    background: #7C8B9D;
    border-radius: 5px;
    border: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.001em;
    color: #FFFFFF;
    margin-left: 12px;
    margin-bottom: 12px;
}

.member_header_data p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.001em;
    color: #5F6D7E;
}

.member_header_data h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #171919;
}
.members-filter-role-loader{
    vertical-align: bottom;
}
.members-btn {
    display: flex;
    align-items: center;
}
.content-end{
    justify-content: end;
}
.customize-button{
    padding:8px 20px;
    font-size: 14px;
    border: none;
}
.add_new_btn {
    min-width: 105px;
    height: 36px;
    border-radius: 8px;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    border: none;
}
.members-filter-name-email {
    display: flex;
}
.members-name-filter-input {
    border-color: #d3d8dc !important;
    height: 45px !important;
}
.members-email-filter-input {
    border-color: #d3d8dc !important; ;
    height: 45px !important;
}
.react-select__menu {
    z-index: 99 !important;
}
.sub-org-members-filter-outer{
    margin-top: 0px !important;
}
.members-filter-role.react-select-container.react-select--is-disabled.css-3iigni-container{
    min-width: 220px;
}

.react-select__control.react-select__control--is-disabled .react-select__value-container.react-select__value-container--is-multi.css-1fdsijx-ValueContainer {
    min-height: 43px;
}

.members-name-filter-input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b0b4b0 !important;
    outline: none !important;
    box-shadow: none !important;

}
.members-email-filter-input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b0b4b0 !important;
    outline: none !important;
    box-shadow: none !important;

}

.user_icon {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    color: white;
    text-align: center;
    padding-top: 6px;
}

.user_inner_content {
    padding: 11px 20px;
    /*  */
    margin: 0px;
}

.user_inner_content li:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    /*  */
    font-size: 18px;
    line-height: 30px;
    color: #171919;
    padding-top: 16px;
    font-family: 'General Sans Medium';
}

.user_inner_content li:nth-child(3) {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #404240;
    font-family: 'General Sans Regular';

}
.members-date-picker-outer{
    display: flex;
    gap: 10px;
}
input.member_search {
    padding: 8px;
    font-size: 12px;
    height: 38px;
}
.display_profile_data {
    display: flex;
    align-items: center;
    /*min-width: 200px;*/
}

.loader-outer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: -20px;
    min-height: 50px;
    justify-content: space-around;
}

.hr-line {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.horizontal-line {
    display: flex;
    height: 3px;
    background: black;
    width: 100%;
    margin: auto;
}
.horizontal-line.section-name-desc-divider {
    width: 80%;
    background: #e2e2e2;
}
.section-name.text-center {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;
}
.section-desc{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;

}
.loader-wrapper {
    width: 100%;
}

.no-member-text {
    font-family: 'General Sans Regular';
    font-size: 14px;
    padding: 10px;
}

.member_data thead tr th:nth-child(3) {
    min-width: 120px;
}

.member_data thead tr th:nth-child(4) {
    min-width: 100px;
}


@media screen and (max-width: 1350px) {
    .members-filter-sech-btn{
        margin-top: 4px;
    }
    .members-filter-outer{
        display: block !important;
    }

    .loader-outer {
        margin-left: -10px;
    }
}
@media screen and (max-width: 1250px) {
    .members-filter-sech-btn{
        margin-top: 4px;
    }

    .members-filter-outer{
        display: block !important;
    }

    .loader-outer {
        margin-left: -10px;
    }
    .members-filter-date-picker-outer {
        display: block !important;
    }
    .members-date-picker-outer {
        justify-content: center ;
        margin-top: 4px ;
    }
    .members-filter-role-loader-outer{
        text-align: center;
        margin-top: 4px;
    }
    .members-filter-role {
        margin-top: 4px;
    }
}


@media screen and (max-width: 1024px) {
    .loader-outer {
        margin-left: 0;
    }

    .user_inner_content li:nth-child(3) {
        font-size: 11px;
    }

    .user_inner_content li:nth-child(2) {
        font-size: 16px;
    }

    .user_inner_content {
        padding: 10px;
    }
}

@media screen and (max-width: 991px) {
    .member_header {
        display: block;
        text-align: center;
    }
    .members-btn {
        justify-content: center;
    }


    .member_header h2 {
        font-size: 16px;
    }

    button.add_new_btn {
        min-width: 82px;
        min-height: 35px;
        font-size: 14px;
    }

    .user_inner_content {
        padding: 12px 12px;
    }

    .user_inner_content li:nth-child(3) {
        font-size: 12px;
    }

    .user_inner_content li:nth-child(2) {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .user_outer {
        width: 100%;
    }

    .member_header h2 {
        font-size: 16px;
    }

    button.add_new_btn {
        min-width: 82px;
        min-height: 35px;
        font-size: 14px;
    }

    .user_inner_content {
        padding: 12px 7px;
    }

    .user_inner_content li:nth-child(3) {
        font-size: 12px;
    }

    .user_inner_content li:nth-child(2) {
        font-size: 16px;
    }
}
@media screen and (max-width: 528px) {
    .members-date-picker-outer {
        display: block !important;
    }
    .members-filter-name-email {
        display: block !important;
    }
    .members-email-filter-input {
        margin-top: 4px;
    }

}

@media screen and (max-width: 480px) {
    .members-btn{
        display: block;
    }
    .add_new_btn{
        margin-top: 8px !important;
    }

}
