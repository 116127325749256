xz.survey_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 18px;
}

.true_background {
    background: #E6E9EC;
    border-radius: 4px;
}

.share-btn {
    margin-top: 11px;
}

.custom-color-active {
    background: #e2e2e2;
    border-radius: 4px;
    padding: 5px;
}

.tabular-section {
    background: #ffff;
    /*border:1px solid #e2e2e2;*/
    padding: 10px;
    border-radius: 4px;
}
.option_Wrapper input {
    margin-left: 0px;
}
.details_container_outer .tag-outer {
    margin-right: 0px;
}
.option_Wrapper {
    gap: 10px;
}

.details_container_outer .option_Wrapper input::placeholder{
    color: #adadad !important;
    font-size: 15px !important;
    font-weight: 300 !important;
}
.question-txt-input::placeholder{
    color: #adadad !important;
    font-size: 15px !important;
    font-weight: 300 !important;
}
.border-gray-on-focus{
    border-color: #dee2e6 !important;
}
.focus-within-bg-white:focus-within {
    background-color: white !important; /* Background color when input is focused */
}
.details_container_outer .add-tag-outer input::placeholder {
    color: #adadad !important;
    font-size: 15px !important;
    font-weight: 300 !important;
}
.details_container_outer .risk-color-and-dropdown {
    margin-right: 0px;
}

.opacity7 {
    opacity: .7;
}

.cmn_font {
    font-size: 12px !important;
    font-weight: 400 !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.cmn_input_size {
    height: 35px !important;
    font-size: 13px !important;
}
.strategy-input::placeholder {
    font-family: 'General Sans Medium';
    font-size: 14px;
    opacity: .8;
    font-weight: 400;
    font-style: normal;

}
.survey-name-input::placeholder {
    font-family: 'General Sans Medium';
    font-size: 14px;
    opacity: .8;
    font-weight: 400;
    font-style: normal;

}

.section-details input {
    height: 51px;
    font-size: 13px;
}

.section-details .form-control:focus {
    outline: 0;
    box-shadow: none;
}

.section-details textarea {
    font-size: 13px;
    height: 51px;
    padding-top: 12px;

}

.add-section {
    display: flex;
    align-items: center;
}

.add-section svg {
    margin-right: 10px;
    cursor: pointer;
}

.section-icon {
    display: flex;
    margin-left: 12px;
    margin-right: 12px;
}

.custom-Icon {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-Icon i {
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;

}

.drag-handle-icon {
    font-size: 18px;
    width: 20px;
    display: flex;
    align-items: center;
}

.share-btn i {
    font-size: 18px;
}

.survey-name-heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
}

.enabled-data {
    font-size: 18px;
    color: green;
}

.disabled-data {
    font-size: 18px;
    color: red;
}

.assigned-surveys label {
    color: black;
    margin-top: 8px;
}

svg.edit-icon {
    font-size: 16px;
}

.trash-icon {
    font-size: 13px;
    cursor: pointer;
}

.survey-name-heading-withOut-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cursor-pointer {
    cursor: pointer !important;
}

.disable-trash-icon {
    color: grey;
    opacity: .7;
    pointer-events: none;
}

.confirmation-box {
    display: flex;
    align-items: center;
}

.i-agree-text {
    /* opacity: .7; */
    font-size: 14px;
    font-family: 'General Sans Medium';
}

span.badge.badge-primary {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.hr-reflection {
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: white;
}

.survey_outer {
    /*background-color: rgba(240, 239, 235, 1);*/
    border-radius: 4px;
}

.sub-org-survey-outer {
    padding: 20px 0px;
    border-radius: 4px;
}

.pd-10 {
    padding: 10px;
}

.custom_table .table thead tr th {
    position: relative;
}

.survey_outer_not_undined {
    border-radius: 4px;

}

.form_inner_content img {
    width: 11px;
    margin-left: 4px;
}

.search-box-add-btn-outer {
    display: flex;
    justify-content: center;
    align-items: center;

}

.survey-table-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    font-family: 'General Sans Medium';
    padding-right: 4px;
}

input.form-control.search-box-input-goal {
    border-radius: 5px;
    height: 42px;
    margin-left: 10px;
    margin-right: -31px;
    padding-right: 76px;
    border-color: #c2c2c2bf !important;
}

input.form-control.search-box-input-tag {
    border-radius: 5px;
    margin-right: -35px;
    margin-left: 10px;
    padding-right: 48px;
    height: 38px;
    border-color: #c2c2c2bf !important;
}

input.form-control.search-box-input-name:focus {
    box-shadow: none;
    background: white !important;
    border: 1px solid #ced4d9;
    border-color: #c2c2c2bf !important;
}

input.form-control.search-box-input-tag:focus {
    box-shadow: none;
    background: white !important;
    border: 1px solid #ced4d9;
    border-color: #c2c2c2bf !important;
}

input.form-control.search-box-input-goal:focus {
    background: white !important;
    box-shadow: none;
    border: 1px solid #ced4d9;
    border-color: #c2c2c2bf !important;
}

input.form-control.search-box-input-name {
    height: 42px;
    margin-right: -35px;
    margin-left: 6px;
    padding-right: 48px;
    border-radius: 6px;
    border-color: #c2c2c2bf !important;
}

.search-box-form {
    display: flex;
    align-items: center;
}

.search-box-form .css-b62m3t-container {
    min-width: 220px;
}

.search-box-form .css-13cymwt-control {
    height: 42px;
}

.survey-search-clr-btn {
    opacity: .6;
    background: white;
    color: #9f9999;
    height: 20px;
    width: 30px;
}

.search-btn {
    /* background: black; */
    border: transparent;
    border-radius: 4px;
    height: 45px;
    min-width: 44px;
}

.search-box-input {

}

.survey_addnew_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: white;
    border: none;
    padding: 9px 20px;
    border-radius: 8px;
}

.form_wrapper {
    border-radius: 10px;
    min-height: 408px;
    margin: auto;

}

.form_container {
    padding: 0px 11px 0px;
    border-radius: 28px;
    background: #E1DAD8;
    overflow: hidden;
}

.form_container_not_un {
    padding: 21px 11px 0px;
    border-radius: 28px;
    overflow: hidden;
}

.form_container_not_un ul {
    border-radius: 12px;

}

.form_container ul {
    border: 2px solid #D7DAD7;
    border-radius: 12px;

}

.form_inner_content {
    display: flex;
    /*justify-content: space-between;*/

}

.checkbox label {
    padding-left: 4px;
}

.checkbox {
    display: flex;
    align-items: center;
}

.surveys {


    display: flex;
    align-items: center;
}


.form_wrapper li:nth-child(1) {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #171919;
    background: #F9F9F7;


}

.form_container_not_un li:nth-child(1) {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #171919;
    background: #F9F9F7;


}

.form_container_not_un ul li {
    padding: 12px;
    background: white;
}

.form_container ul li {
    padding: 12px;
    background: white;
}

.form_wrapper li:last-child {
    border-bottom: unset;
}

.form_wrapper li {
    border-bottom: 1px solid #E4E5E1;
    padding: 16px 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #171919;

}

.form_inner_content h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.prebtn img {
    width: 17px;
    cursor: pointer;
}

.prebtn span {
    font-size: 14px;
    cursor: pointer;
}

.date {
    display: table-header-group;
    font-weight: 500;
    font-size: 12px;
    color: #171919;
    position: absolute;
    top: 15px;
}

.reflections_table thead tr th:nth-child(2) span, .reflections_table thead tr th:last-child span {
    padding-right: 4px;
}

th.custom-th .date {
    position: absolute;
    right: 57px;
}

/*.custom_table {*/
/*    margin: 0px 15px;*/
/*}*/

.custom_table .table thead {
    background: #F8F9FB;
}

.custom_table table tbody tr th label {
    font-size: 14px;
    color: #171919;
}

.custom_table .table tbody tr {
    vertical-align: middle;
}

.custom_table .table tbody {
    background: #ffff;
}

.custom_table .table thead .surveys-heading {
    display: flex;
}

.cursor-pointer {
    cursor: pointer;
}

.custom_table .table thead .surveys label {
    font-size: 13px;
    font-weight: 500;
}

.custom_table .table thead .surveys img {
    width: 10px;

}

.date img {
    width: 10px;
}

.del_btn {
    color: #404240;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    background: #F3F2EF;
    border: 1px solid #D7DAD7;
    border-radius: 8px;
    padding: 12px 20px;
    margin-right: 10px;

}

.survey_heading {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #171919;
}

.disable-edit-reflection-name-btn {
    opacity: .7;
    cursor: default;
}

.disable-edit-reflection-pencil-btn {
    opacity: .7;
    cursor: default;
    background: #e9ecef;
}
.disable-btn {
    opacity: .7;
    cursor: default;
}

th.custom-th {
    display: table-cell;
    text-align: -webkit-right;
    position: relative;
}

.name_title {
    font-size: 15px;
    font-weight: 500;
    color: #222222;
    text-transform: capitalize;
    font-family: 'Inter';
}

.date_display_text_chg h6 {
    text-align: end;
    margin-top: 10px;
    opacity: 0.7;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.date_display_text_chg span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}


.down_icon {
    padding-left: 5px;
}

.custom_table table.table {
    border-top-left-radius: 8px;
    overflow: hidden;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.risk-color-and-dropdown {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 30px;
}

.add-Icon-option {
    display: flex;
    align-items: center;
    margin-right: 13px;
    position: absolute;
    right: 0;
    top: 16px;
    cursor: pointer;
}

.add-Icon-button button {
    padding: 3px 6px;
    border: transparent;
    border-radius: 3px;
    font-family: 'General Sans Medium';
}

svg.edit-reflection-pencil-btn {
    height: 15px;
    width: 15px;
}

.unassign-icon {
    height: 21px;
    width: 20px;
    margin-top: -5px;
    color: red;
}

.disable-unassign-icon {
    opacity: .5;
    cursor: default;
    color: grey !important;
    pointer-events: none;
}

.search-box-form-reflection-org {
    width: 90% !important;
}

.search-box-input-assigned-unassigned {
    border-radius: 0px !important;
}

.search-box-input-assigned-unassigned-outer {
    margin-right: -5px !important;
}

.img_data {
    width: 47px;
    height: 33px;
    display: flex;
    margin-left: 10px;
}

.dis_img {
    width: 21px;
    width: 40px;
    height: 40px;
    margin-left: -13px;
    border-radius: 50%;
}

.dis_img img {
    width: 100%;
}

.bar_data {
    width: 50%;
    display: flex;
}

.bar_data .progress {
    height: 9px;
    width: 70%;
    --bs-progress-bar-bg: #5F6D7E;
}
.select-strategy-label{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.wrap-text {

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.bar_data span {
    margin-left: 10px;
    margin-top: -5px;
    font-size: 13px
}

.question-length-exceeds-warning {
    padding-top: 2px;
    color: red;
    font-size: 12px;
    margin-left: 10px;
}

.option-length-exceeds-warning {
    color: red;
    font-size: 12px;
    margin-left: 76px;
}

.date_display h6 {
    margin-top: 10px;

}

.survey_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 0 15px;*/
}

.search-box-input-active-inactive {
    margin-left: 4px;

}

.search-box-input-goal-modal {
    border-radius: 0 6px 6px 0 !important;
}

/*.search-box-input-active-inactive .survey-search-box-input-active-inactive.react-select__control.css-13cymwt-control {*/
/*    border-radius: 0px 6px 6px 0px !important;*/
/*}*/
.search-box-form-reflection {
    /*width: 80%;*/
    margin-left: auto;
}

.form-check-input:checked {
    background-color: #3f4144;
    border-color: #77787a;
}

/*reflections*/
.reflections_table thead tr th:nth-child(2), .reflections_table thead tr th:last-child {
    min-width: 150px;
}

.reflection-skeleton-loader .loader-wrapper .loader-outer {
    margin-left: -50px;
}

.survey-search-clr-btn:hover {
    opacity: 1;
}

.options-list-container.mt-2 {
    position: relative;
    padding-bottom: 30px;
}

.add-Icon-button {
    position: absolute;
    bottom: 0;
    margin-left: 3px;
    border-radius: 4px;
}

.add-Icon-button i {
    font-size: 12px;
}

.segment-details {
    position: absolute;
    right: 0;
    top: -20px;
    display: flex;
    margin-top: 7px;
    align-items: center;
    cursor: pointer;

}

.segment-details .form-control {
    height: 35px;
    font-size: 13px;
    margin-right: 6px;
}

.segment-details svg {
    margin-right: 10px;
}

/*search input*/

.reflection_filters button {
    border-radius: 10px;
    border: 1px solid #EEE;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    color: #5E5D62;
    padding: 9px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 81px;
}

.reflection_filters {
    gap: 10px;
    display: flex;
    align-items: center;
}

.reflection_search_wrapper input {
    height: 33px !important;
}

.reflection_filters button img {
    margin-right: 5px;
}


.reflection_search_wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #EEEEEE;
    box-shadow: 0 0 5px rgba(0, 0, 0, 5%);
    border-radius: 10px;
    padding: 3px 10px;
    background: #FFF;
    max-width: 350px;
    width: 100%;
}


.reflection_search_wrapper input:focus {
    box-shadow: unset;
}

.reflection_search_wrapper input {
    border: transparent;
    background: transparent;
    font-size: 14px;
}

@media screen and (max-width: 1400px) {
    .reflection-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -40px;
    }

    .search-box-form-reflection-org {
        width: 100% !important;
    }
}


@media screen and (max-width: 1240px) {
    .reflection-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -18px;
    }

    .form_wrapper {
        width: 97%;
    }

    .search-box-add-btn-outer {

        margin-top: 12px;
    }
}

@media screen and (max-width: 1200px) {
    .search-box-form-reflection {
        width: 100%;
    }
}


@media screen and (max-width: 1024px) {
    .reflection-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -10px;
    }

    .form_wrapper {
        width: 97%;
    }
}

@media screen and (max-width: 1199px) {
    .form_wrapper {
        width: 97%;
    }
}


@media screen  and (max-width: 1100px) {
    .form_wrapper {
        width: 100%;
    }

    .search-box-input-active-inactive .survey-search-box-input-active-inactive.react-select__control.css-13cymwt-control {
        border-radius: 6px !important;
    }

    .search-box-input-active-inactive {
        margin-left: 0px !important;
    }

    .search-box-input-assigned-unassigned-outer {
        margin-right: 0px !important;
        margin-bottom: 4px !important;
    }

    .search-box-input-assigned-unassigned {
        border-radius: 6px !important;
    }

    .cross-btn-outer {
        display: inline-block;
    }

    .survey_heading {
        font-size: 20px;
    }

    .survey_wrapper {
        display: block;
        text-align: center;
    }

    .form_wrapper {
        width: 100%;
    }

    .search-box-add-btn-outer {
        display: block;
    }

    .search-box-form {
        display: block;
    }

    input.form-control.search-box-input-name {
        display: inline-block;
        border-radius: 6px;

    }

    input.form-control.search-box-input-goal {
        display: inline-block;
        border-radius: 6px;
        margin-bottom: 5px;
        margin-right: -31px;
        padding-right: 46px;
        margin-top: 5px;

    }

    input.form-control.survey-attempt-search-box-input-tag {
        display: inline-block;
    }

    input.form-control.search-box-input-tag {
        display: inline-block;
        margin-top: 5px;
        border-radius: 6px;
    }

    .survey_heading {
        font-size: 16px
    }

    .search-box {
        display: block;
        margin: auto;
        margin-bottom: 8px;
        margin-right: 0px;
    }

    input.form-control.search-box-input {
        min-width: 130px;
    }


    .form_inner_content h5 {
        font-size: 12px;
    }

}

@media screen and (max-width: 991px) {
    .reflection-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: 0px;
    }

    .survey_wrapper {
        padding: 20px 23px;
    }

    .search-box-input-goal-modal {
        border-radius: 6px 6px 6px 6px !important;
    }


}

/*testing chekput page*/
.question_tabs_wrapper {
    width: 100%;
    border: 1px solid #e2e2e2;
    border-radius: 10px !important;
    min-height: 500px;
    background: #F5F5F5 !important;
    padding-left: 10px;
}

.content_wrapper {
    width: 100%;
}

.content_wrapper_outer {
    width: 100%;
}

.content_wrapper_outer .tabular-section.main_content {
    margin-top: 0px;
}

span.question_name {
    font-size: 14px;
    margin-left: 5px;
}

.question_tabs_wrapper .nav-link {
    color: #000;
    padding: 10px;
    text-align: left;
  box-shadow: 9px 11px 10px #f5f2f2;
}

.question_tabs_wrapper button.add_question_btn {
    background: transparent;
    border: transparent;
    color: orange;
}

.add-ques {
    display: flex;
    justify-content: center;
}

.add-section button {
    border: transparent;
    margin-top: 10px;
    border-radius: 10px;
    font-size: 13px;
    padding: 7px;
}

.question_tabs_wrapper .nav-pills .nav-link.active {
    background: transparent;
    color: #000;
}

.active-link {
    background: #d7877385;
    color: #fff !important;
    border-radius: 8px;
}


.question_tabs_wrapper .flex-column.nav.nav-pills {
    border-radius: 4px;
}

.sub_question {
    padding-left: 40px;
}

.sub_question a {
    font-size: 12px;
}

span.question_count {
    display: inline-block;
    width: 22px;
    height: 22px;
    background: white;
    text-align: center;
    line-height: 21px;
    border-radius: 8px;
    font-size: 12px;
    color: black;
    border: 1px solid #ddd;
}
.question_tabs_wrapper .nav-item {
    margin-top: 10px;
    background: white;
    border-radius: 8px;
}
.question_tabs_wrapper .sub_question .nav-link {
    border: transparent;
}

/*table css*/
.custom_table.colored_table div[role="rowgroup"] .rdt_TableRow:nth-child(even) {
    background: #F9FAFB;
}


.filter_input_wrapper {
    position: relative;
}

.filter_input_wrapper .cross-btn-outer {
    position: absolute;
    right: 3px;
    top: 8px;
}

.bulk_trash_icon {
    font-size: 16px;
    margin-right: 8px;

}
