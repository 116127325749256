
.box_design1 h5 {
    font-style: normal;
    font-size: 22px;
    color: #171919;
}

.reflection-list {
    padding: 0px;
}

.dashboard-reflection-outer {
    display: flex;
    align-items: center;
}

.dashboard-skeleton {
    width: 200px !important;
    border: 1px solid #e2e2e2;
}

.select-reflection-txt {
    font-size: 14px;
    font-family: 'General Sans Medium';
    font-weight: 800;
    letter-spacing: .03rem;
    margin-right: 10px;
}

.dashboard-reflection-dropdown-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.members-filter-role{
    font-size: 13px;
}
.dashboard-skeleton {
    max-width: 220px;
    height: 35px;
}

.dashboard-reflection-dropdown {
    max-width: 220px;
    max-height: 300px;
    height: 40px;
    font-size: 13px;
}

.background_mentee {
    padding-right: 14px;
    padding-left: 6px;
}

.display_profile_data .placeholder-outer .placeholder-container {
    height: 30px;
    width: 30px;
}

.display_profile_data .cursor-pointer .placeholder-container {
    cursor: pointer !important;
}

.main_wrapper {
    min-height: 100vh;
    position: relative;
    background: #FFFFFF;
}

.thoughts-main {
    padding: 0;
}

.box_design2 h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #171919;
    margin-left: 12px;
}

.card-header {
    background-color: white;
}

.box_design2 {
    padding: 20px;
    /*background: rgba(240 239 235);*/
    border-radius: 5px;
}

.display_data svg.CircularProgressbar {
    width: 100%;
    height: 265px;
    margin-top: 13px;
}

.box_design3 {
    padding: 20px;

    background: rgba(225, 218, 216, 1);
    border-radius: 5px;
}

.box_design1 .form-group {
    margin-left: auto;
    width: 115px;
    height: 22px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    position: relative;
}

.box_design1 .form-group .form-control {
    line-height: 16px;
    padding: 10px 16px;
    font-size: 14px;
}

.list_data {
    padding: 7px;
}

.box_design1 .form-group .image_icon {
    position: absolute;
    top: 9px;
    right: 17px;
}

.box_design3 h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #171919;
    margin-left: 12px;
    margin-top: 10px;
}

.box_design1 .form-group .image_icon img {
    width: 12px;
}

.no-thought-text {
    padding: 20px;
    background: #f96b29;
    opacity: 0.7;
    border-radius: 5px;
     color: #fff;
    text-align: center;
    font-size: 16px;
    box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.25) inset, 0px 2px 2px 0px rgba(132, 45, 5, 0.15);

}

.display_data {
    background-color: #ffff;
    border-radius: 6px;
    flex-grow: 1;
    padding: 2px 0;
    padding-bottom: 70px;
}

.circle-custom {
    display: flex;
    justify-content: center;
    position: relative;

}

.circle-data {
    display: flex;
    gap: 10px;
}

.members-table-outer {
    padding: 10px;
    /*background: rgba(240, 239, 235, 1);*/
    border-radius: 5px;
}


.members-table {
    border-radius: 5px;
}

.members-table table {
    margin-bottom: 0px;
}

.members-table table tbody {
    background: #ffff;
}

.members-table table thead {
    border-radius: 10px;
    vertical-align: middle;
    background: #F9F9F7;
    border-bottom: 1px solid #D7DAD7;
}

.members-table table thead tr th, .members-table table tbody tr th {
    vertical-align: middle;
    padding: 10px 7px;
}

.members-table table tbody tr td {
    padding: 10px 7px;
    vertical-align: middle;
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #171919;

}

.members-table table thead tr th {
    padding: 14px 7px;
}

.members-table table tbody tr td:nth-child(2) {
    opacity: .7;
    padding-left: 20px;
    min-width: 105px;
}

.members-table table tbody tr td:last-child {
    min-width: 125px;
}

.members-table table tbody tr th .display_profile_data img {
    width: 35px;
}

.result-status {
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #171919;


    padding: 5px;
    border-radius: 7px;
    padding: 5px 10px;
    opacity: 1 !important;
}

.member-email {
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #171919;
    /*opacity: .7;*/
}

.members-table table thead tr th img {
    width: 10px;
}


.create-thought-for-text {
    font-size: 15px;
    opacity: .9;
}

select.create-thought-for-select {
    /*max-width: 160px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

select.create-thought-for-select.form-select:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}

.reset-form {
    border-radius: 4px;
    padding: 8px;
}

.custom_font {
    font-size: 20px;
}


.rotate-btn {
    flex: 0 0 49px !important;
    background: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
    border-radius: 4px;
    text-align: center;
}

.add_new_button {
    margin-left: auto;
}

.add_new_button.notes_cta {
    display: flex;
    padding-left: 10px;
}

/*.cmn_secondary{*/
/*    flex: 0 0 44px;*/
/*}*/
span.show_more {
    color: #dc4f30;
    display: inline-block;
    padding-left: 2px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
}

.user_nameAnd_email h6 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 16px;
    position: relative;
    margin-bottom: 0;
    word-break: keep-all !important;
}

.search_list {
    display: flex;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    margin-right: 10px;
    height: 30px;
}

.delete-note {
    cursor: pointer;
}

.edit-note {
    cursor: pointer;
}

.search_list input {
    border: transparent;
    padding: 0px 10px;
    font-size: 15px;
}

.search_list input:focus {
    box-shadow: unset;
}

.add_new_button.notes_cta .search-thought {
    border: none;
    height: 30px;
    min-width: 30px;
    flex: 0 0 30px;
    padding: 0;
}

.add_new_button.notes_cta button {
    border: none;
    height: 30px;
    font-size: 13px;
    min-width: 100px;
    flex: 0 0 30px;
    padding: 0;
}

.user_nameAnd_email {
    overflow: hidden;
}

.add_new_button .search_list button {
    margin-left: -30px;
    border: transparent;
    margin-right: 0;
    background: white;
}

.add_new_button button {
    border-radius: 6px;
    padding: 4px;
    border: 1px solid #D7DAD7;
    min-width: 44px;
    height: 40px;
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 15px;
}

.user_details {
    display: flex;
    min-height: 30px;
    align-items: center;
    justify-content: space-between;

}

.thought-name-and-date-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
}
.box_design2 .card {
    border: 1px solid #e2e2e2;
}

.box_design2 .card .card-header {
border-bottom: 1px solid #e2e2e2;
    padding: 10px;

}
.thoughts-Icon {
    margin-right: 6px;
}

.thought-name-and-date {
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 31px; */
    letter-spacing: 0.5px;
    color: #404240;
    opacity: 0.7;

}


.thought-name-and-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user_details img {
    width: 32px;
    height: 32px;
    margin-top: 0px;

}


.user_nameAnd_email h6 {
    margin-top: 0px;
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    display: block;
    letter-spacing: 0.5px;
    color: #171919;
}

.user_nameAnd_email p {
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /*line-height: 31px;*/
    letter-spacing: 0.5px;
    color: #404240;
    opacity: 0.7;
    margin-bottom: 0px;
}

.list_data li {
    color: #171919;
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.circle-background {
    fill: none;
    stroke: #e2e2e2;
}

.circle-progress {
    fill: none;

}

.circle-text {
    color: #171919;
    font-weight: 600;
    font-family: 'Gill Sans medium';
}

.list_data span {
    float: right;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #171919;
}

/* //another component css */

.heading_block {
    display: block;
    position: relative;
}

.heading_block small {
    top: 46px;
    position: absolute;
    font-size: 14px;
    left: 17px;

}

.heading_block h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #2E3646;
}

.viewReport {
    display: flex;
}

.d-flex.viewReport button {
    margin-left: auto;
    width: 95px;
    height: 46px;
    border-radius: 8px;
    background: #2E3646;
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #F8F9FB;
    border: transparent;
}


.card_details {

    height: 154px;
    background: #F8F9FB;
    border: 1px solid #5F6D7E;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 5px;
    padding: 18px;
    position: relative;
}

.user-icon_section {
    display: flex;
}

.user-icon_section .img_display {
    width: 40px;
    height: 40px;
    margin-left: -8px;
    border: 2px solid #F8F9FB;
    border-radius: 50%;
}

.user-icon_section img:first-child {
    margin-left: 0;
}

.thoughts-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img_display img {
    width: 100%;
}

.view_btn {
    display: flex;
    position: absolute;
    top: 20px;
    right: 17px;
}


.card_details button {
    margin-left: auto;
    width: 54px;
    height: 35px;
    border: 1px solid;
    border: 1px solid #D1D9E2;
    background: #F8F9FB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
    color: #5F6D7E;
}

.repo_user_info {
    position: absolute;
    top: 79px;
    left: 1px;
}

.repo_user_info p {
    position: absolute;
    left: 15px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: -0.001em;
    color: #5F6D7E;
}

.repo_user_info h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #2E3646;
}

.userInfo_data {
    border-top: 1px solid #F0EFEB;
    margin-top: 16px;
    padding: 10px 12px;
    position: relative;
}

.userInfo_data ul {
    display: flex;
    overflow-y: hidden;
    max-width: 100%;
    flex-wrap: initial;
    min-width: 20px;
    position: absolute;
    overflow-x: auto;
    width: 95%;
}

.user_list_items {
    display: flex;
}

.userInfo_details h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.03em;
    color: #171919;
}

.userInfo_details {
    padding: 0 17px;

}

/*.member-name{*/
/*    width: 150px;*/
/*}*/
.members-overall-result-table {
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 1px solid #e0e0e0;
}

.dashboard-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: #171919;
}

.userInfo_details h5 {
    color: #9C9F9C;
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    margin-left: 0px;
}

.box_design {
    padding: 10px;
    background: #E6E9EC;
}

.usericon {
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 1441px) {
    .innerComponents {
        /*padding-left: 268px !important;*/
        margin-left: 240px !important;
    }

    .userInfo_data {
        width: 100% !important;

    }
}

/*@media screen and (max-width: 1440px) {*/
/*    .innerComponents {*/
/*        !*padding-left: 268px !important;*!*/
/*        margin-left: 268px !important;*/
/*    }*/
/*    !*.userInfo_data {*!*/
/*    !*    width: 574px !important;*!*/

/*    !*}*!*/
/*    !*.permission_check .userInfo_data {*!*/
/*    !*    width: 100% !important;*!*/

/*    !*}*!*/
/*}*/

@media screen and (max-width: 1024px) {
    .innerComponents {
        /*padding-left: 264px !important;*/
        margin-left: 264px !important;
    }

    .circle-data {
        display: block;
        gap: 10px;
    }

    .display_data {
        padding-bottom: 0px;
    }

    .userInfo_data {
        display: flex;
        align-items: center;
        height: auto !important;
        overflow-x: scroll;
        overflow-y: hidden;
        width: auto !important;
        border-top: 1px solid #F0EFEB;
        margin-top: 22px;
        padding: 40px;
    }

    .rotate-btn {
        margin-top: 12px;
    }

    .rotate-btn img {
        padding-top: 8px !important;
    }

    .heading_block h5 {
        font-size: 24px;
        line-height: 28px;
    }

    .heading_block small {
        position: unset;
    }

    .box_design2 h5 {
        font-size: 20px;
        margin-left: 0px;
    }

    .add_new_button {
        margin-left: 8px;
    }

    .user_details {
        margin-left: 0px;
    }

    .user_details img {
        height: 30px;
        width: 30px;
    }

    .user_nameAnd_email {
        margin-left: 4px;
    }

    .user_nameAnd_email h6 {
        font-size: 14px;
    }

    .box_design2 {
        padding: 16px;
    }
}

@media screen and (max-width: 991px) {
    .overall-result-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -30px;
    }

    .innerComponents {
        /*padding-left: 265px !important;*/
        margin-left: 265px !important;
    }

}

@media screen and (max-width: 800px) {
    .overall-result-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -20px;
    }
}

@media screen and (max-width: 767px) {
    .overall-result-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: -25px;
    }

    .innerComponents {
        /*padding-left: 75px !important;*/
        margin-left: 0px !important;

    }

    .kjmbrI {
        padding: 8px 2px 8px 5px !important;
    }

    .heading_block h5 {
        font-size: 24px;
        line-height: 28px;
    }

    .heading_block small {
        position: unset;
    }

    .heading_block small {
        position: unset;
    }

    .d-flex.viewReport button {
        font-size: 13px;
        width: 73px;
        height: 33px;
    }

    .heading_block h5 {


        line-height: 15px;

    }

    .box_design1 h5 {
        margin-left: 0px;
    }

    .repo_user_info {
        padding-left: 10px;
    }

    .repo_user_info p {
        left: unset;
    }
}

.box_design3 {
    padding: 16px;
}

.box_design2 {
    padding: 10px;
    margin-top: 4px;

}

.list_scroll {
    max-height: 366px;
    overflow: auto;
}

.rotate-btn img {
    padding-top: 180px
}

.box_design2 h5 {
    font-size: 16px;
}

.box_design1 .form-group .image_icon {
    top: 4px;
}

.box_design1 .form-group .form-control {
    padding: 7px;
}

.pagination_row .buttons ul {
    padding: 0px;
}

/* new=== */
.add_new_button {
    margin-left: auto;
}


@media screen and (max-width: 575px) {
    .overall-result-skeleton-loader .loader-wrapper .loader-outer {
        margin-left: 0px;
    }

    .thought-heading {
        display: block !important;
    }

    .add_new_button.notes_cta {
        margin-bottom: 10px;
        justify-content: center;
        padding-left: 0px;
    }
}

@media screen and (max-width: 420px) {
    .add_new_button.notes_cta{
        display: block;
        text-align: center;
    }
    .add_new_button.notes_cta button{
        margin-top: 6px;
    }
    .add_new_button.notes_cta .search-thought{
        margin-top: 0;
    }
}
