.strategy_action_wrapper svg{
    font-size: 18px;
}
.create-strategy-btn{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 22px;
    color: white !important;
    border: none !important;
    padding: 9px 20px !important;
    border-radius: 8px !important;
}
.strategy_description_input{
    min-height: 180px !important;
}
.strategy-goals-input-field:focus{
    box-shadow:none!important;

}
.strategy-goals-input-field{
    border: none;
    min-width: 130px;
    flex-grow: 1;
    font-size: 14px;
}
.modalWrapperBox .form-group h3 {
    opacity: .7;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    border: 1px solid #d7dad7;
    border-radius: 8px;
    padding: 4px 9px;
}
.view-description{
    border: 1px solid #d7dad7;
    border-radius: 8px;
    padding: 4px 9px;
    opacity: .7;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    max-height: 100px !important;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: auto;
    max-width: 100%;
    font-family: "Inter", sans-serif;

}
.edit-strategy-input:focus{
    box-shadow: none;
    border-color: #dee2e6;
}
.strategy-assigned-reflections .react-select__control {
    border-radius: 8px;
    background: #F5F5F5 ;
}
.strategy-assigned-reflections .react-select__control--is-focused {
    background: white !important;
    box-shadow: none !important;
    /*border: ;*/
}

.strategy-assigned-reflections .react-select__control .react-select__value-container .react-select__placeholder {
    font-size: 13px;
    font-weight: 400;
}
.strategy-assigned-reflections .react-select__control .react-select__value-container .react-select__multi-value .react-select__multi-value__remove:hover {
    background-color:#e5e5e5;
    color: black;
}
.strategy-assigned-reflections .react-select__control .react-select__value-container .react-select__multi-value .react-select__multi-value__remove svg {
    height: 18px;
    width: 20px;
    margin-top: 2px;
}
.strategy-assigned-reflections .react-select__control .react-select__value-container .react-select__multi-value .react-select__multi-value__label {
    font-size: 14px;
    color:black;
}

.strategy-assigned-reflections .react-select__control .react-select__value-container .react-select__multi-value  {
    background-color:#e5e5e5;
}
.hr{
    width: 100%;
    height: 1px;
    background: #EEEEEE;
}
.strategy-detail-outer{
    margin: 0px 18px;
}
.edit-strategy-description-input{
    border-radius: 8px;
    min-height: 150px !important;
}
.strategy-goal-outer{
    padding: 2px;
    border: 1px solid #d7dad7;
    flex-wrap: wrap;
    border-radius: 8px;
}
.strategy-goal{
    font-size: 15px;
    border-radius: 8px;
    background: #e5e5e5;
    margin: 2px;
    padding: 6px 8px;
}


.strategy-goal-input-outer:focus-within{
    background: white ;
}
.strategy-goal-input-outer{
    min-height: 38px;
    background: #F5F5F5 ;
    border:1px solid #dee2e6;
}
.assigned-reflection-txt-btn-outer{
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.strategy-goals-outer{
    margin-top: 26px;
}
@media (max-width: 1024px) and (min-width: 768px) {
    .assigned-reflection-txt-btn-outer {
        display: block;
        text-align: center;
        margin-top: 28px;
    }
}

@media (max-width: 420px) {
    .assigned-reflection-txt-btn-outer {
        display: block;
        text-align: center;
    }
}