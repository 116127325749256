.show_header_data_other {
    padding: 7px 17px !important;
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #171919;
    vertical-align: middle;
}
.show_header_data_other span{
    opacity: 0.7;
}

.org-Data {
    color: #DB4C2D;
    font-size: 19px;
    cursor: pointer;
}
.organization-details{
    display: flex;
    padding-left: 0;
}
td.show_header_data_other.high_risk button {
    background: #FBD9A7;
    border: 1px solid  #FBD9A7;
    color: #171919;
    font-size: 13px;
    width: 80px;
}
.user_position_table .display_date {
    width: 22%;
}
.organizationss-detail-table{
    border-bottom: 1px solid #e0e0e0;
    border-radius: 15px 15px 0 0 !important;
}
.update-org-level-pulse-logo-outer {
    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
    padding: 30px 15px;
    border-bottom: 1px solid #cdcdca;
    border-radius: 10px 10px 0 0;
}
.update-org-level-outer{
    box-shadow: 1px 10px 10px #80808075;
    border-radius: 10px;
}

.update-org-level-pulse-logo{
    height: 23px;
    width: 95px;
    filter: invert(1);
    opacity: 1;
}
.org-detail-table-loading  .loader-wrapper .loader-outer {
    margin-left: -60px;
}
@media screen and (max-width: 1399px) {
    .org-detail-table-loading  .loader-wrapper .loader-outer {
        margin-left: -50px;
    }
}
@media screen and (max-width: 1199px) {
    .org-detail-table-loading  .loader-wrapper .loader-outer {
        margin-left: -30px;
    }
}
@media screen and (max-width: 991px) {
    .org-detail-table-loading  .loader-wrapper .loader-outer {
        margin-left: -10px;
    }
}
@media screen and (max-width: 892px) {
    .org-detail-table-loading  .loader-wrapper .loader-outer {
        margin-left: -5px;
    }
}
@media screen and (max-width: 767px) {
    .org-detail-table-loading  .loader-wrapper .loader-outer {
        margin-left: -10px;
    }
}
@media screen and (max-width: 680px) {
    .org-detail-table-loading  .loader-wrapper .loader-outer {
        margin-left: 0px;
    }
}
@media screen and (max-width: 300px) {
    .member-detail-buttons{
        flex-direction: column;
    }
    .promote-to-manager-btn{
        margin-top: 4px;
    }
}