.profile_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.connect-api-key-outer-layout{
    border: 1px solid #e2e2e2;
    border-radius: 4px;
}
.api-key{
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    padding:5px 10px;
}
.sender-email-note {
    font-size: 12px;
    color: red;
    margin-left: 2px;
    margin-top: 3px;
}
.no-key-txt{
    font-size: 13px;
    color: red;
}
.connect-api-key-modal{
    min-height:200px
}
.delete-key-icon{
    font-size: 19px;
    margin-left: 5px;
}
.profile_container h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #171919;
}

.cancel-plan-date {
    font-size: 13px;
    font-family: 'General Sans Light';
    font-weight: bold;
    text-align: end;
    color: red;
}

.renew-plan-txt {
    font-size: 13px;
    font-family: 'General Sans Light';
    font-weight: bold;
    text-align: end;
    color: green;
}
.custom-picker{
    position: relative;
}
.custom-picker input{
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
}
.moving-data input{
    margin-top: 20px;
}
.custom-picker label {
    width: 422px;
}

.custom-picker label img{
    border-radius: 4px;
    width: 100%;
}
input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
}

.cancel-plan-btn-outer {
    text-align: end;
}
.choose-color {
    display: flex;
}

.choose-color input[type="color"] {
    height: 38px;
    padding: 1px;
    border-radius: 8px;
    width: 70%;
    border: none;
}

.setting_cancel_btn {
    padding: 8px 15px;
    margin-right: 10px;
    border: transparent;
    border-radius: 8px;

}

.orgPef-button {
    display: flex;
}

.orgPef-button button {
    margin-left: auto;
    margin-top: 12px;
    margin-right: 12px;
    padding: 9px 20px;
}

form.detail_form {
    border: 1px solid #e2e2e2;
    padding: 14px;
    border-radius: 4px;
}

form.orgPef {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
}

.setting_save_btn {

    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
    color: #ffffff;
    padding: 12px 18px;

}

.detail_form input::placeholder {
    color: #404240;
    font-style: normal;
    /*font-weight: 600;*/
    font-size: 14px;
    line-height: 20px;
    font-family: 'General Sans light';
}


Form label {

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #9C9F9C;
}

/*.detail_form{*/
/* margin-top: 40px;*/
/*    */
/*}*/
.detail_form label {
    color: #404240;
}

/*.setting_outer{*/
/*    padding: 10px;*/
/*}*/
.reset_password_btn {
    width: 146px;
    height: 42px;
    margin-top: 20px;
    border-radius: 8px;
    border: transparent;
}
.org-pref{
    padding:10px;

}

.divided-inputs {
    width: 100%;
    position: relative;
    margin-top: 18px;
    display: flex;
}

.choose-color .first-color {
    margin-top: 13px;
    width: 45px;
    margin-right: 10px;
    border-radius: 4px
}

.profile-choose {
    position: relative;
}


.profile-choose img {
    position: absolute;
    right: 0;
    height: 87px;
}

.profile-choose .icon-stage {
    position: absolute;
    width: 25px;
    right: -7px;
    top: -9px;
    z-index: 9;
    background: #d5caca;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    line-height: 22px;
}
.icon-stage i{
    font-size: 12px;
}
.choose-color{
    position: relative;
}
.choose-color .icon2-stage {
    position: absolute;
    width: 25px;
    right: -7px;
    top: -9px;
    z-index: 9;
    background: #d5caca;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    line-height: 22px;
}

.icon2-stage i{
    font-size: 12px;
}

.moving-data {
    width: 70%;
}

.moving-data input#inputAddress {
    margin-top: 20px;
}

.second-color {
    width: 95px;
    border-radius: 4px;
    margin-top: -17px;
    position: absolute;
    right: 0;
    height: 62px;
}

.setting-user {
    display: flex;
}

.setting-user  .cmn_setting_btn {
    margin-left: auto;
    height: 42px;
    margin-top: 21px;
}

.plan-price-outer {
    display: flex;
    align-items: end;
    margin-bottom: -10px;
}

.other-info-txt {
    font-size: 18px;
    font-family: 'General Sans Medium';
    margin-top: 5px;
    margin-left: 1px;
    margin-bottom: 15px;
}

.other-info-data {
    font-size: 14px;
    font-family: 'General Sans Medium' !important;
    opacity: .7;
}

.font-light {
    opacity: .7;
}

.days-left-border {
    border: 1px solid #e2e5e0;
    padding: 7px 13px;
    border-radius: 6px;
}

.plan-interval {
    font-family: 'General Sans Light';
    margin-left: 7px;
}

.plan-status {
    display: inline-block;
    border-radius: 6px;
    padding: 5px 9px;
    color: green;
    height: 32px;
    font-weight: 900;
    font-family: 'General Sans Medium';
}
.green-color{
    color: green;
}
.red-color{
    color: red;
}

.days-left-outer {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.other-plan-info-outer {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.plan-interval-txt {
    font-size: 16px;
    font-family: 'General Sans Medium';
}

.plan-name {
    font-size: 26px;
    font-family: 'General Sans Medium';
}

.price-txt {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 6px;
}

.plan-price {
    display: flex;
    align-items: end;
    min-width: 92px;
}

.vertical-divider-plans {
    display: inline-block;
    width: 50%;
    margin-top: -10px;
    height: 1px;
    background: #eaecf0;
}

.plans_btn {
    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
    color: #ffffff;
    padding: 8px 10px;
    border: 1px solid white;
}

.horizontal-divider {
    display: inline-block;
    border-radius: 10px;
    margin-left: 24px;
    width: 3px;
    height: 28px;
    background: red;
    margin-right: 10px;
    margin-bottom: 8px;
}

.pulse_logo {
    width: 144.78px;
    height: 20px;
    position: absolute;
}

.personal-info-heading {
    border-radius: 7px 7px 0 0;
    background: white;
    padding: 12px 0px 11px 11px;
    margin: -1px;
    border-bottom: 1px solid #e2e5e0;
    border-top: 1px solid #e2e5e0;
    border-left: 1px solid #e2e5e0;
    border-right: 1px solid #e2e5e0;
}


.content-mid{
    justify-content: space-around !important;
}

@media (min-width: 575px) {
    .modal-lg, .modal-xl {
        --bs-modal-width: 600px;
    }
    .modal-xl-2 {
        --bs-modal-width: 600px !important;
    }
}
@media (min-width: 1199px){
    .modal-xl {
        --bs-modal-width: 800px !important;
    }

}

@media screen and (max-width: 767px) {

    .custom-picker label {
        width: 305px;
    }

    .second-color {
        width: 73px;
        border-radius: 4px;
        margin-top: -17px;
        position: absolute;
        right: 0;
        height: 49px;
    }
    .profile_container h2 {
        font-size: 16px;
    }

    .profile_container {
        display: block;
        text-align: center;
    }

    .setting_outer {
        padding: 10px;
    }

}

@media (max-width: 465px) {
    .days-left-outer {
        display: block;
        margin-top: 10px;

    }

    .sub-txt {
        text-align: center;
        margin-bottom: 5px;
    }
    .sub{
        text-align: center;
    }
    .cancel-plan-btn-outer.mt-3 {
        display: flex;
        flex-direction: column;
    }
    button.cmn_setting_btn.mediumFontSize.plan-btn.me-2.plans_btn  {
        margin-right: 0px !important;
    }
    button.cmn_setting_btn.mediumFontSize.plans_btn.me-2{
        margin-right: 0px !important;
    }

}


@media (max-width: 420px) {
    .plan-price-outer {
        display: block;
    }
    .horizontal-divider {
         margin-left: 0px;
    }
    .plan-name {
        margin-bottom: 6px;
    }
    .other-plan-info-outer {
        display: block;
    }
}


@media (max-width: 417px) {
    .cancel-plan-btn-outer {
        text-align: center;
    }
    .cancel-plan-btn-outer .plans_btn {
        margin-top: 10px;
    }
}
@media (max-width: 1445px) {
    .custom-picker label {
        width: 370px;
    }
}

