@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@font-face {
  font-family: 'General Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('General Sans Regular'), url('/src/fonts/GeneralSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'General Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('General Sans Bold'), url('/src/fonts/GeneralSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'General Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('General Sans Light'), url('/src/fonts/GeneralSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'General Sans Medium';
  font-style: normal;
  font-weight: normal;
  src: local('General Sans Medium'), url('/src/fonts/GeneralSans-Medium.woff') format('woff');
}

.App {
  overflow: hidden;
  /*background: #CFCFD0;*/
  min-height: 100vh;
}
.cmn_radius{
  border-radius: 10px !important;
}
.App_loader {
  overflow: hidden;
  background: black;
  min-height: 100vh;
  opacity: 0.4;
}
body .colored_table {
  padding-top: 16px !important;
}
.cmn_modal_btn {
  border-radius: 10px;
  /*width: 62px;*/
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

}

.innerComponents {
  min-height: 100vh;
}

hr {
  margin: 0.5rem 0 !important;
}
.disable-btn{
  background: #858282;
  opacity: .7;
  pointer-events: none;
}

.content_wrapper {
  padding-top: 60px;
  min-height: calc(100vh - 60px);
}
body,span{
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
body ul {
  list-style: none;
  padding-left: 0px;
  font-family: "Inter", sans-serif;
}

body h2 {
  margin-bottom: 0px;
}

.cmn_btn {
  background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
  border-radius: 10px;
  width: 91px;
  height: 45px;
  border: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ffff;
}

.cmn_form_btn {
  background: #F3F2EF;
  border: 1px solid #D7DAD7;
  border-radius: 10px;
  width: 102px;
  height: 45px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #404240;

}

.my__loader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
}
.toast_data{
  z-index: 1;
}
.loader {
  width: 60px;
  height: 60px;
  border: 5px solid #b6b4b4;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cmn_heading {
  padding-top: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 44px;
  color: #171919;
}

.cmn_outer {

  width: 670px;
   background: #ffff;
  border-radius: 4px;
  margin: 3rem;
  padding: 30px;

}

.cmn_background_color {
  background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
  color: #ffff;
}

.cmn_color_icon{
  color: #f8552b;
}

.display-grid{
  display: grid;
}
.cmn_background_color_disable {
  background: #3936364a;
  color: white;
  pointer-events: none;
}

.main_content {
  /*margin-top: 46px !important;*/
  margin-left: 12px !important;
  margin-right: 12px !important;

}
.justify-content-end{
  justify-content: end;
}
.justify-content-space-between{
  justify-content: space-between;
}
.align-item-flex-start{
  align-items: flex-start;
}
.main_content_inner {
  margin-top: 16px !important;
  margin-left: 12px !important;
  margin-right: 3px !important;
}
.header-data{
  display: contents;
}
.header-data h3{
  font-size: 18px;
  margin-top: 10px;
  margin-left: 15px
}
.box_design1 {
  padding: 10px ;
  padding-top: 16px;
  border-radius: 5px;
}

.boldFontSize {

  font-family: "Inter", sans-serif;

}

.lightFontSize {

  font-family: "Inter", sans-serif;

}

.regularFontSize {

  font-family: "Inter", sans-serif;

}

.mediumFontSize {

  font-family: "Inter", sans-serif;

}

/*.form-group input::placeholder {*/
/*  color: rgb(128, 128, 128);*/
/*  font-size: 12px;*/
/*  line-height: 20px;*/
/*  font-family: "Arial", sans-serif;*/

/*}*/
.cmn_setting_btn {
  border: transparent;
  border-radius: 8px;
  padding:10px 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}

.component_content {
  border-radius: 30px 0 0 0;
 margin-left: 240px;



}
/*cmn header*/
.cmn_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  border-bottom: 1px solid #EEEEEE;
}

.cmn_header h2 {
  font-size: 18px;
}
/*modal*/
.modal_outer.modal-body {
  font-size: 13px;
}
/* //pagination  */
.pagination_row {
  background: white;
  padding: 10px;
  border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  display: flex;
  /*margin-bottom: 8px;*/
}

.pagination_row .arrow_img_prev {
  margin-left: 3px;
  cursor: pointer;
  margin-top: 3px;
}

.pagination_row .arrow_img_prev span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #404240;
}

.pagination_row .arrow_img_next {
  margin-right: 4px;
  margin-top: 2px;
  cursor: pointer;
  display: -webkit-inline-box;
}
.disable{
  pointer-events: none;
  opacity: 0.4;
}

.opacity-1{
  opacity: 1 !important;
}
.opacity-4{
  opacity: 0.4 !important;
}
.opacity-7{
  opacity: 0.7 !important;
}
.disable-icon{
  cursor:default;
  opacity: .7;
}
.border-none{
  border:none !important;
}
.bg-none{
  background: none;
}
.pagination_row .arrow_img_next span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #404240;
}

.pagination_row .buttons {
  margin: auto;

}

.pagination_row .buttons ul {
  display: flex;
  padding: 5px;
  margin: 0;

}

.pagination_row .buttons ul li {
  list-style-type: none;
  padding: 8px;
  margin: 1px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 8px;
  color: #404240;
  height: 24px !important;
  border-radius: 4px;
}

.active {
  background: #F0EFEB;
  width: 21px;
  height: 23px;
}
/* new */
.cmn_fontStyle{
  font-style: normal;
font-size: 14px;
line-height: 20px;
}
.form-check-input:checked {
  background-color: #161718 !important;
  border-color: #1c1e21 !important;
}
.form-check-input:focus {
  border-color:#161718!important;
  outline: 0 !important;
  box-shadow: unset!important;
}
.custom_width{
  width: 520px;
}
.gray_bg_color{
  background-color: #F5F5F5 !important;
}
.white_bg_color{
  background-color: white !important;
}
.box-shadow-unset-on-focus:focus{
  box-shadow: unset !important;
}
.survey-tag-outer:focus-within {
  background-color: white !important; /* Background color when input is focused */
}
.font-weight-500{
  font-weight: 500 !important;;
}
.font-size-15{
  font-size: 15px !important;
}
.border-radius-8{
  border-radius: 8px;
}
.align-items-end{
  align-items: end;
}
.word-break-all{
  word-break: break-all;
}
/*new section css */
.on_focus:focus{
  background-color: white !important;
}
.add_section_container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  gap: 10px;
  align-items: center;
}

.sectionTitle{
  background: white;
  width: 100%;
  padding: 6px 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.segment_container{
  display: flex;
  gap:10px;
  cursor: pointer;
}
.edit-icon svg{
  font-size: 14px;

}
.cmn-icon-outer {
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 6px;
  text-align: center;
}
/*end*/
@media screen and (max-width:767px){
  .pagination_row {
    padding: 8px;
    /* width: 120% !important; */

}
  .component_content{
    border-radius: 30px 30px 0 0;
  }
  .pagination_row .arrow_img_next span {
    font-size: 12px;
}
.pagination_row .arrow_img_prev span{
  font-size: 12px;
}
}
input[type=radio]:checked{
  accent-color: #171919;;
}
input[type=radio]{
  height: 18px;
  width: 22px;
  flex: 0 0 18px;
  accent-color: red;
}
.close_btn{
  margin-left: auto;
  cursor: pointer;

}
.modal_cancel_btn {
  padding: 9px 20px;
  background: #e4e4e4;
  border: none;
  border-radius: 8px;
  color: #404240;
}

.modal_reset_btn {
  padding: 9px 20px;
  border: none;
  border-radius: 10px;
}

:focus-visible {
  outline: none !important;
}
.table_design1{
  border-radius: 10px 10px 10px 10px !important;
  border: 1px solid #e0e0e0 !important;
  background: #ffff;
}
.table_design2{
  border-radius: 10px 10px 0px 0px !important;
  border-top: 1px solid #e0e0e0 !important;
  border-left: 1px solid #e0e0e0 !important;
  border-right: 1px solid #e0e0e0 !important;
  background: #ffff;
}
div.tjYmo:not(:last-of-type){
  border-bottom-color: #E4E6EC;
  background-color: #fff;
}
.sc-eTNRI.lnOUep {
  color: #5E5D62;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}
.pagination-border{
  border: 1px solid #e0e0e0;
}
.react-datepicker-popper{
  z-index: 99 !important;
}

.search_filter_common_modal .react-datepicker-wrapper{
  display: inline-block;
  padding: 0;
  border: 0;
  width: 50% !important;
}

.search_filter_clear{
  width: 76px;
}


.bulk_delete_Main_header{
  font-weight: bold;
}
