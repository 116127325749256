.roles_heading_outer {
    display: flex;
    align-items: end;
    justify-content: end;
    border-radius: 4px;
    margin-bottom: .25rem;
}
.heading-filter-reports-outer-members-detail{
    margin-top: 0px !important;
}
.roles_outer {
    padding: 10px;
}

.sub-org-roles_outer {
    padding: 20px 0;
}
.graph-search-bar-loader{
    padding-top: 3px;
}

.date-custom-report .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: -webkit-fill-available;
}

.report-criteria {
    margin-top: 19px;
}

.border-customize {
    border: 1px solid #e2e2e2;
}

.user-summary-graph-reflections-loader{
    margin-left: 3px;
    margin-right: 2px;
}
.reports-dropdown {
    max-width: 220px;
}

.reports-type-dropdown-outer {
    display: flex;
    align-items: center;
}

.reports-dropdown-skeleton {
    width: 220px;
    height: 34px;
}

.reports-dropdown:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b0b4b0;
    outline: none;
    box-shadow: none;
}


.filter-attempt-form {
    display: flex !important;
    justify-content: end;
}

.survey-name-search {
    border-radius: 5px;
}
.report-range-from{
    width: 50%;
}
.report-interest .css-b62m3t-container{
    width: 100%;
    margin-left: 10px;
}
.report-range-from .search-by-survey-name {
    height: 33px;
    padding: 12px;
    box-shadow: none;
    width: 100%;
    background: white !important;
    border: 1px solid #ced4d9;
}

.search-by-survey-name {
    height: 45px;
    padding: 12px;
    box-shadow: none;
    width: 100%;
    background: white !important;
    border: 1px solid #ced4d9;
}
.fromDate .react-datepicker-wrapper {
    width: 100%;
    font-size: 14px;

}
.toDate .react-datepicker-wrapper {
    width: 100%;
    font-size: 14px;

}
.react-select__control.react-select__control--is-disabled.css-16xfy0z-control {
    min-height: 44px;
}


.x-title .form-control {
    font-size: 14px;
}

.y-title {
    width: 50%;
    margin-left: 2px;
}
.y-title .form-control {
    font-size: 14px;
}
.report-interest .form-control {
    font-size: 14px;
}

.report-interest .form-select {
    font-size: 14px;
}
.fromDate {
    width:50%
}
.toDate {
    width:50%;
    margin-left: 9px;
}
.fromDate .search-by-survey-name{
    height: 35px;
    padding: 12px;
    box-shadow: none;
    width: 100%;
    background: white !important;
    border: 1px solid #ced4d9;
}

.toDate .search-by-survey-name{
    height: 35px;
    padding: 12px;
    box-shadow: none;
    width: 100%;
    background: white !important;
    border: 1px solid #ced4d9;
}
.x-title {
    width: 50%;
    margin-left: 2px;
}
.date-range-class {
    display: flex;
    margin-top: 20px;
}
/*.report-period .css-13cymwt-control, .css-t3ipsp-control {*/
/*    min-height: 37px !important;*/
/*    width: 335px !important;*/
/*    border: 1px solid #ced4d9 !important;*/
/*    box-shadow: unset !important;*/
/*    font-size: 14px;*/
/*}*/
.report-period {
    display: flex;
}

.date-custom-report {
    display: flex;
    width: 100%;
    margin-top: 8px;
    font-size: 14px;
}

.search-by-survey-name:focus {
    box-shadow: none;
    background: white !important;
    border: 1px solid #ced4d9;
}

.overflow-scroll {
    overflow: scroll !important;
}

.graph-icon {
    cursor: pointer;
}

.bar-graph-report-loader-outer .my__loader {
    position: static;
}

.bar-graph-report-loader-outer {
    height: 300px;
}

.bar-chart-outer {
    background-color: white;
    padding: 20px;

    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-radius: 10px 10px 0 0;
}

.bar-chart-outer2 {
    background-color: white;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
}
.org-list-data {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    margin-bottom: -11px
}
.custom-width{
    width: 35%;
}
.bar-chart-container {
    height: 400px;
}

.member-summary-line-graph-container {
    justify-content: center;
}

.col-xl-6.bar-chart-outer2.member-summary-line-graph-outer.mt-2 {
    margin-left: 10px;
    width: 49%;
}
.disable-graph{
    opacity: 0.3;
}
.legend-txt {
    font-size: 12px;
    white-space: nowrap;
    opacity: 0.7;
}

.col-xl-6.bar-chart-outer2.member-summary-spider-graph-outer.mt-2 {
    margin-right: 10px;
    width: 49%;
    margin-left: -1px;
}

.legend-color {
    display: inline-block;
    height: 12px;
    width: 40px;
    margin-right: 4px;
}

.user-attempt-graph-legend-tot-attempt-color {
    background: #4298d7;
}

.user-attempt-graph-legends-stag-color {
    background: red;
}

.user-attempt-graph-legends-gro-color {
    background: #ff7201;
}

.user-attempt-graph-legends-mat-color {
    background: green;
}

.user-attempt-graph-legends-res-na-color {
    background: #ffb2c1;
}

.user-attempt-graph-legends-outer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.reflection-summary-graph-legends-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.heading-dates-filter-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-select__input-container {
    max-width: 150px;
}

.disable-datepicker {
    background-color: #e1e1e13b !important;
    color: grey !important;
}

.user-attempt-graph-legend-outer {
    display: flex;
    align-items: center;
    margin-left: 13px;
}

.usr-smry-graph-srch-btn-disable {
    opacity: .6;
    cursor: default !important;
}

/*.report-by-surveyId-table {*/
/*    border-bottom: 1px solid #e0e0e0;*/
/*    border-radius: 15px 15px 0 0 !important;*/
/*}*/
/*.sub-org-report-by-surveyId-table {*/
/*    border-radius: 15px 15px 0 0 !important;*/
/*    border: 1px solid #e0e0e0;*/
/*}*/

/*.report-by-surveyId-search-box {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: end;*/
/*}*/

button.export-btn-survey-report {
    height: 45px;
    min-width: 44px;
    border-radius: 4px;
    background: #efefef;
    border: 1px solid #c1bdbd;
    color: black;
}

.mentee-report-search {
    display: flex;
}

span.mentee-report-date-range {
    display: flex;
}

.date-and-surveyName {
    display: flex;
}

.cont-end {
    justify-content: end;
}

.date-range-survey-report {
    display: flex;
}

.report-search-survey-btn {
    min-width: 100px;
}
.apply-date-reports-btn{
    min-width: 65px;
    font-weight: 600;
}
.heading-filter-reports-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.report-sub-heading {
    word-break: keep-all;
    margin-right: 20px;
    white-space: nowrap;
}
.user-attempt-metrics-heading{
    word-break: keep-all;
    white-space: nowrap;
    margin-top: 30px;
}


.filters-outer {
    display: flex;
    justify-content: end;
}
.filters-outer .css-b62m3t-container{
    min-width: 220px;
}
.filters-outer .css-13cymwt-control{
    height: 45px;
}

.date-picker-outer {
    display: flex;
    justify-content: end;
}

.join-graph-search-btn-outer {
    text-align: center;
}
.member-attempt-smry-graph-heading-export-btn-outer {
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.report_add_btn {
    margin-left: 10px;
    margin-top: 5px;
}
.report-interest {
    display: flex;
}
.report-interest .filter-data {
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
    align-items: center;
}
.reports-x-y-fields label{
    font-size: 12px;
    margin-left: 2px;
}
.custom-report .form-control {
    font-size: 14px;
}
.custom-report .form-control label{
    margin-left: 2px;
}
.custom-report .form-control label{
    margin-left: 2px;
}

.custom-report .form-select {
    font-size: 14px;
}
.reports-x-y-fields select {
    font-size: 14px;
}
.reports-x-y-fields {
    display: flex;
    width: 100%;
}
.x-field-data {
    width: 50%;
}

.y-field-data {
    width: 50%;
}
/*.user-join-graph-outer.bar-chart-outer2.overflow-scroll canvas{*/
/*    max-width: 2600px !important;*/
/*    width:100% !important;*/
/*}*/
.heading-dates-filter-outer-sub-org{
    justify-content: end;
}

.filter-outer {
    display: flex;
    align-items: center;
    justify-content: end;
}
.mentee-report-search-btn {
    display: flex;
}
.share_reflection_wrapper .css-13cymwt-control, .share_reflection_ .form-control {
    min-height:180px;
    align-items:baseline;
}

.share_reflection_wrapper .css-t3ipsp-control {
    min-height:180px;
    align-items:baseline;
}
@media screen and (max-width: 1366px) {
    .search-by-survey-name {
        max-width: 100% !important;
        width: 94%;
    }
}

@media screen and (max-width: 1422px) {
    .filter-attempt-form-survey {
        display: block !important;
    }

    .search-by-survey-name {
        max-width: 180px;
    }

    .select-view-user-report {
        margin-bottom: 4px;
    }

    .select-view-survey-report {
        margin-bottom: 4px;
    }


    .date-and-surveyName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
    }

    .report-search-survey-btn {
        margin-top: 4px;
        text-align: center;
    }

    .date-range-survey-report {
        display: flex;
    }

}


@media screen and (max-width: 1340px) {
    .heading-filter-reports-outer-members-detail{
        display: block;
    }
    }
@media screen and (max-width: 1250px) {
    .col-xl-6.bar-chart-outer2.member-summary-line-graph-outer.mt-2 {
        margin: 0px;
        width: 100%;
        min-height: 400px;
    }

    .col-xl-6.bar-chart-outer2.member-summary-spider-graph-outer.mt-2 {
        margin: 0px;
        width: 100%;
    }

    .member-summary-line-graph-container {
        margin: 0;
    }

    .heading-dates-filter-outer {
        display: block;
    }
    .heading-filter-reports-outer{
        display: block;
    }
}

@media screen and (max-width: 1232px) {
    .roles_heading_outer {
        display: block;
    }

    .date-range-survey-report {
        display: flex;
    }

    .mentee-report-search-btn {
        text-align: center;
    }

    .mentee-report-search {
        display: block;
    }

    .mentee-report-date-range {
        display: flex;
        margin-top: 4px;
        align-items: center;
        justify-content: center;
    }

    .mentee-report-search {
        text-align: center;
        margin-bottom: 4px;
    }

    .reports-txt {
        margin-bottom: 10px;
        text-align: center;
    }

    .reports-type-dropdown-outer {
        justify-content: center;
        margin-bottom: .75rem !important;
    }

    .filter-attempt-form {
        display: block !important;
    }

    .member-summary-graph-date-picker {
        justify-content: center;
    }


    .member-summary-graph-srch-btn {
        text-align: center;
    }


}

@media screen and (max-width: 550px) {
    .reflection-summary-outer-heading-filter {
        text-align: center;
        display: block !important;
    }
    .survey_heading {
        font-size: 21px;
    }
}
@media screen and (max-width: 991px) {
    .reflection-summary-outer-heading-filter {
        text-align: center;
        display: flex;
    }
    .user-summary-graph-reflections-loader{
        margin-right: 0;
        margin-left: 0;
        margin-top: 4px;
        width: 80% !important;
    }
    .user-summary-graph-user-loader{
        width: 80% !important;
    }

    .user-attempt-metrics-heading{
        text-align: center;
    }
    .user-smry-grph-heading{
        text-align: center;
    }
    .filters-outer {
        display: block;
    }
    .member-summary-graph-reflection-list {
        margin-top: 4px;
    }

    .join-graph-srch-btn-outer {
        text-align: center;
        margin-top: 4px;
    }

    .date-picker-outer {
        margin-top: 4px;
        justify-content: center;
    }

}

@media screen and (max-width: 921px) {
    .date-and-surveyName {
        display: block;
    }

    .surveyName-survey-report {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .date-picker-outer-report {
        display: block;
        position: relative;
    }
    .date-picker-outer-report .cmn_color_icon {
        position: absolute;
        right: 0;
        bottom: 14px;
    }

    .reports-date-picker-outer {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }
    .reports-date-picker-outer .react-datepicker-wrapper{
        width: 100% !important;
        margin-top: 12px !important;
    }

    .date-range-survey-report {
        display: flex;
        align-items: center;
        margin-top: 4px;
        justify-content: center;
        width: 100%;
    }
}
@media screen and (max-width: 1440px) {
    .search-by-survey-name {
        max-width: 100%;
        width: 94%;
    }
}
@media screen and (max-width: 528px) {
    .date-range-survey-report {
        display: inline-block;
        text-align: center;

    }
    .user-attempt-metrics-heading{
        text-align: center;
        font-size: 16px;
    }
    /*.member-attempt-smry-graph-heading-export-btn-outer{*/
    /*    display: block !important;*/
    /*}*/

    .date-picker-outer {
        display: block;
        text-align: center;
    }

    .reports-date-picker-outer {
        display: block !important;
    }

    .user-join-toDate {
        margin-top: 4px;
    }

    .mentee-report-date-range {
        display: inline-block;
    }

    span.mentee-report-date-range {
        display: block;
    }

}

@media screen and (max-width: 450px) {
    .reports-type-dropdown-outer {
        display: block;
        text-align: center;
    }

    .reports-dropdown {
        margin: auto;
    }

    .reports-dropdown-skeleton {
        width: 70%;
    }

    .filter-outer {
        display: block;
    }

    .reflection-summary-graph-search-btn-outer {
        margin-top: 4px;
    }


}
.report_toggle_btn{
    text-align:right;
    justify-content: end;
    align-items: center;
}

/* ToggleSwitch.css */

.toggle-switch {
    display: inline-block;
    width: 40px; /* Adjusted to accommodate the icon size */
    height: 20px;
    position: relative;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(46, 160, 209);
    transition: 0.4s;
    border-radius: 20px; /* Adjusted for a rounded switch */
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px; /* Adjusted to 20px */
    width: 20px;  /* Adjusted to 20px */
    left: 0; /* Adjusted for proper positioning */
    bottom: 0; /* Adjusted for proper positioning */
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: rgb(46, 160, 209);
}

input:checked + .slider:before {
    transform: translateX(20px); /* Adjusted for the new icon size */
}

.toggle-switch-text {
    margin-left: 10px;
    font-size: 14px;
    vertical-align: middle;
}
.half_scroll .rdt_TableBody {
    max-height: 239px;
    overflow: auto;
}