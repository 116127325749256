.reflection-metrics-container {
    padding: 10px;
    /*background: rgba(240, 239, 235, 1);*/
    border-radius: 5px;
}

.reflection-metrics-tile-container {
    margin: 0;
}
.dashboard-reflection-dropdown:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b0b4b0;
    outline: none;
    box-shadow: none;

}
.members-detail-graph{
    max-height: 330px;
}

.members-report-table .loader-wrapper .loader-outer {
    display: flex;
    align-items: center;
    margin-left:-30px;
    padding: 15px;
}




.images div .placeholder-container{
    height: 30px;
    width: 30px;
    border: 1px solid white;
    margin-left: -6px;
}
.images{
    display: flex;
    align-items: center;
}
.reflection-metrics-tile {
    overflow: hidden;
    word-break: break-word;
    background: white;
    padding: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 10%);
}
.chart {
    width: 150px !important;
    height: 80px !important;
}
.high-risk-tile {
    border-radius: 8px ;
    margin-left: 8px;
}
.low-risk-tile {
    border-radius:6px ;
}

.good-tile {
    border-radius:6px ;
    margin-left: 8px;
}

.high-risk-tile-outer {
    padding: 0;
}

.low-risk-tile-outer {
    padding: 0;
}
.chart-outer-skeleton {
    text-align: center;
}
.good-tile-outer {
    padding: 0;
}
.metrics-data-outer {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 12px;
    align-items: end;
    justify-content: space-between;
}

.metrics-data-outer-custom {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d2d2d2;
    padding-bottom: 12px;
    color: #c1c1c1;
}
.see-all-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 3px 0;
    padding-right: 3px;
    padding-left: 6px;
}
.see-all-btn-icon {
    height: 11px;
    width: 11px;
}
.disable-see-all-btn {
    color: grey;
    cursor: default !important;
}

.images img {
    margin: 0 0 0 -6px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid white;
}
.disable-images {
    opacity: .3;
}
.see-all-btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    opacity: .8;
    font-family: 'General Sans Medium';
    font-size: 13px;
    letter-spacing: .01rem;
}
.compare-text {
    font-size: 12px;
    opacity: .7;
}

.up-arrow {
    border-radius: 50%;
    background: #c2f7c2;
    padding: 6px;
    height: 22px;
    width: 22px;
}
.down-arrow{
    border-radius: 50%;
    background: rgb(242 54 43 / 30%);
    padding: 6px;
    height: 22px;
    width: 22px;

}
.metrics-percentage-high-risk {
    word-break: keep-all;
    color: #0d870d;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.text-red{
    color: #f82d2d;
}
.total-reflections {
    word-break: keep-all;
    font-size: 25px;
    font-weight: 500;
}
.metrics-heading {
    font-size: 13px;
    font-family: 'Inter';
    font-weight: 600;
    letter-spacing: .03rem;
}
.data {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1199px) {
    .high-risk-tile {
        margin-top: -1px;
    }
}
@media screen and (max-width: 850px) {
    .dashboard-reflection-dropdown-outer {
        flex-direction: column;
    }
    .dashboard-reflection-dropdown-outer h2.mediumFontSize.mt-2 {
        overflow: hidden;
        word-break: break-all;
        margin-bottom: 10px;
    }
}
@media screen and (max-width:500px) {
    .dashboard-reflection-dropdown-outer{
        display: block;
    }
    .select-reflection-txt{
        text-align: center;
    }
    .dashboard-reflection-dropdown {
        margin: auto;
    }
    .dashboard-reflection-outer{
        display: block;
        margin-bottom: 3px;
    }
    .reflection-list{
        text-align: center;
    }
}

@media screen and (max-width:460px) {
    .metrics-data-outer{
        display: block;
    }
    .compare-text{
        text-align: center;
    }
    .data{
        margin-top: 20px;
        justify-content: center;
    }
    .chart{
        margin: auto;
        height: 100px !important;
        width: 170px !important;
    }
    .members-report-table .loader-wrapper .loader-outer {
        margin-left:-10px;
    }

}
@media screen and (max-width:320px) {
   .see-all-outer{
       flex-direction: column;
   }
    .see-all-btn{
        justify-content: center;
        margin-top: 15px;
    }
}