.header-assigned-reflection {
    background: #FFFFFF;
    padding: 10px;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 10000;
    align-items: center;
}

.reflection_heading {
    flex-grow: 1;
    text-align: center;
}

.assigned-reflections-outer {
    background: #FFFFFF;
    border: 1px solid #e2e2e2;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}
.answer-assigned-reflections-outer {
    background: #FFFFFF;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    margin-bottom: 10px;
}

.assigned-reflections-wrapper {
    max-width: 950px;
    width: 100%;
    padding-top: 60px;
    margin: auto;
}
.assigned-reflections-result-wrapper {
    max-width: 80%;
    width: 100%;
    padding-top: 60px;
    margin: auto;
}