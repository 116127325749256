.save_survey_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap:7px
}

.save-survey-outer {
    margin: 0px 13px 0px 22px !important;
}

/*.strategy-input:focus {*/
/*    background: white !important;*/

/*}*/

.strategy-input {
    min-height: 110px !important;
    border: 1px solid #D7DAD7;
    color: black;
    /*background: #F5F5F5;*/
    padding: 10px 20px;
}

.spiritual_pathways2 .form-control::placeholder {
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 500;
}

.submit-survey-btn {
    margin-right: 10px;
}

.survey-name-outer {
    width: 50%;
}

.survey-name-text {
    margin-left: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.nav-item {
    margin-top: 10px;
}

.section-data {
    background: #f9f9f7;
    border-radius: 4px;
    padding: 10px;
}

.section-data .form-control {
    height: 35px;
    font-size: 14px;
}

.section-data label {
    font-size: 13px;
    color: #000;
}

.spiritual_pathways2 .form-control {
    height: 36px;
}

.edit-survey-title-btn {
    cursor: pointer;
    margin: 4px 7px 7px 7px;
    max-width: 68px;
    font-size: 20px;
}

.publish-survey-btn {
    height: 41px;
    min-width: 80px;
}

.reflection-lvl-dropdown {
    height: 41px;
    width:180px;
}

.cursor-default {
    cursor: auto !important;
}

.submit-survey-btn {
    height: 41px;
    min-width: 80px;
    margin-right: 10px;
}

.disable-btn {
    cursor: default;
    background: #858282;
    opacity: .7;
}

.options-list-container .option-select-container:last-child .option-heading {
    border-bottom: transparent;
}

.option-heading {
    margin-left: 4px;
    padding: 0 0 0 0;
    border: 1px solid white;
    display: flex;
    align-items: center;
    width: 87%;
    font-weight: 600;
}

.reflection-type-dropdown {
    height: 30px;
}

.reflection-type-dropdown:focus {
    color: #212529;
    background-color: #fff;
    border-color: #b0b4b0;
    outline: none;
    box-shadow: none;
}

.range-value-input:focus {
    color: #212529;
    border-color: #dee2e6;
    outline: none;
    box-shadow: none;

}

.range-value-input {
    max-width: 55%;
}

.number-range-value-outer {
    margin-left: 4px;
}

.min-value-container {
    display: flex;
    align-items: center;
    margin-right: 48px;
}

.max-value-container {
    display: flex;
    align-items: center;
    margin-right: 48px;
}

.max-value-heading {
    min-width: 50px;
    font-family: inherit;
    font-size: 15px;
    font-weight: 500;
}

.good-bad-dropdown {
    border: none;
    width: 125px;
}

.good-bad-outer {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.option-text-outer {
    display: flex;
}

.option-input {
    margin-left: 11px;
    width: auto;
    flex-grow: 1;
    font-size: medium;
    font-family: 'General Sans Regular';
}
/*.option-input:focus{*/
/*    border: 1px solid #e2e2e2;*/
/*}*/
.add-ques-btn-container {
    min-height: 500px;
    border: 1px solid #e2e2e2;
    border-radius: 3px;
}

.option-input::placeholder {
    opacity: .9;
    font-family: 'General Sans light';
    font-size: 15px;
}

.color-data-high-risk {
    border-radius: 5px;
    margin-top: 2px;
    margin-right: 6px;
    background: red;
    display: inline-block;
    height: 10px;
    width: 18px;
}

.color-data-low-risk {
    border-radius: 5px;
    margin-top: 2px;
    margin-right: 6px;
    background: orange;
    display: inline-block;
    height: 10px;
    width: 18px;
}

.color-data-good {
    border-radius: 5px;
    margin-top: 2px;
    margin-right: 6px;
    background: green;
    display: inline-block;
    height: 10px;
    width: 18px;
}

.option-select-container {
    padding-top: 3px;
    padding-bottom: 4px;
}

.option-container {
    display: flex;
    justify-content: space-between;
}


.option-container:last-child {
    border-bottom: none;
}

.option-input-value {
    border: none;
}

.delete-ques-btn {
    cursor: pointer;
}

/*.option-input:focus {*/
/*    color: #212529;*/
/*    background: white;*/
/*    box-shadow: 0 0 5px white;*/
/*    border-color: white;*/
/*    outline: 0;*/
/*}*/

.tag-outer {
    padding: 3px;
    border: 1px solid #d7dad7;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    margin-right: 48px;
}

.survey-tag-outer {
    padding: 2px;
    border: 1px solid #d7dad7;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    background: white;

}

.survey-tag-outer.mt-1.ms-3.w-50 {
    margin-left: 25px !important;
}

.tag-text {
    display: inline-block;
}

.tag-cross {
    cursor: pointer;
    margin-left: 4px;
    display: inline-block;
}


.add-tag-icon {
    margin-left: 8px;
    font-size: 13px;
    opacity: 0.5;
}

.tags-input-field {
    border: none;
    min-width: 130px;
    padding: 5px;
    flex-grow: 1;
    font-size: 13px;

}

.add-tag-outer {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.goals {
    border-radius: 3px;
    display: inline-block;
    background: #e5e5e5;
    margin: 2px;
    padding: 3px 6px 0px 6px;
}

.tags {
    border-radius: 3px;
    display: inline-block;
    background: #e5e5e5;
    margin: 2px;
    padding: 3px 6px 0px 6px;
}

.survey-tags {
    font-size: 14px;
    border-radius: 3px;
    display: inline-block;
    background: #e5e5e5;
    margin: 2px;
    padding: 3px 6px 4px 6px;
}

.goals-outer {
    border: 1px solid #d7dad7;
}

.userImage img {
    height: 80px;
    width: 80px;

}

.options-outer {
    width: 100%;
}

.row_customize {
    padding: 10px 12px;
}

.userImage {
    display: flex;
    align-items: center;
}

.user_information {
    padding-left: 17px;
}

.user_information h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #171919;
    margin-top: 12px;
}

.user_information p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #404240;
}

.dataSubmit_btn {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: white;
    height: 47px;
    width: 90px;
    border: transparent;
    border-radius: 8px;
}

.save_survey_btn {
    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
    border: 1px solid #D7DAD7;
    color: white;
    margin-right: 10px;
    height: 50px;


}

.SurveyName_container {
    border-radius: 4px;
}

.crossIcon {
    margin-top: 3px;
    width: 27px;
    height: 23px;
    border: 1px solid #D1D9E2;
    border-radius: 5px;
    padding: 2px 6px 24px 6px;
    margin-right: -3px
}

.add-quest-btn-container {
    display: flex;
    align-items: center;
}

.add-quest-btn-container > div {
    flex-grow: 1;
    height: 1px;
    background-color: white;
}

.question-card {
    background: white;
    border: 1px solid #D7DAD7;
    border-radius: 6px;
    margin-top: -6px;
    font-size: 14px;
    width: 100%;
    min-height: 500px;
}

.required-checkbox {
    margin-right: 10px;
}

label.required-label {
    display: flex;
    align-items: center;
}

input.required-checkbox {
    accent-color: green !important;
    background: red;
    margin-left: 10px;
}

.question-type-dropdown {
    border-radius: 8px;
    border-color: #c0c2c0;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

span.section_title {
    background: #ffff;
    position: absolute;
    top: -23px;
}

.section_title {
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px;
}

.section_title > span {
    position: absolute;
    top: -10px;
    background: #ffffff;
    padding: 2px 3px;
    font-size: 15px;
    font-weight: 600;
}

.required-check {
    display: flex;
    align-items: center;
    justify-content: end;
}

.required-check h5 {
    margin-right: auto;
    font-size: 13px;
}

.add-ques_btn {
    margin: 0 10px;
}


.add-ques_btn {
    border: 1px solid #e2e2e2;
    border-radius: 22px;
    height: 39px;
    margin-left: auto;
    background: white;
    min-width: 133px;
}

.add-quest-btn-container {

}

.crossIcon img {
    width: 10px;
    margin-top: -6px;
}

.caret {
    color: #a4a7a4;
    position: absolute;
    top: 5px;
    left: 9px;
}

.dropdown button {
    border: 1px solid #D1D9E2;
    height: 23px;
    width: 27px;
}

.dropdown button:hover {
    border: 1px solid #D1D9E2;
}

.dropdown {
    margin-top: 1px !important;
}

.slider-interval-outer {
    width: 98.7%;
}

.slider {
    color: #000;
}

.rating-star {
    cursor: pointer;
}

.rating-star-outer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.interval-outer {
    opacity: 0.3;
    margin-top: -12px;
    display: flex;
    justify-content: space-between;
    font-size: x-small;
}

.interval {
    display: inline-block;
}

.form-content {
    display: flex;
    flex-direction: row;
    column-gap: 19px;
    align-items: center;
    min-width: 249px;
    justify-content: space-between;

}

.add-option-input-field {
    padding-bottom: 17px;
}

.question-dropdown-outer {
    display: flex;
}

.add-option-input-button {
    display: flex;
    align-items: center;
}

.remove-option-outer {
    margin-bottom: 10px;
}

.answer-input-field {
    padding-bottom: 17px;
}


.radio-group div label {
    margin-right: 10px;
    margin-left: 5px;
}

/*.form-range:focus{*/
/*    outline: none !important;*/
/*}*/
/*.form-range:focus-visible{*/
/*    border: none!important;*/
/*}*/
.range-interval-outer {
    display: flex;
    align-items: center;
}

.form-range:focus,
.form-range:focus-visible {
    outline: none;
    box-shadow: none;
}

.form-range::-webkit-slider-thumb {
    background-color: #d7dad7; /* Set the button color to black */
}

.form-range::-moz-range-thumb {
    background-color: black;
}

.form-range::-ms-thumb {
    background-color: black;
}

.positiveNegative {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    /*width: 288px;*/
    padding: 3px;

}

.range-value {
    border-radius: 5px;
    border: 1px solid #d7dad7;
    margin-left: 20px;
    padding: 2px 6px 0px 6px;
}

.positiveNegative h2 {
    /*color: #9C9F9C;*/
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-right: 110px;
}
.reflection_detail_para input::placeholder {
    color: #adadad !important;
    font-size: 15px !important;
    font-weight: 300 !important;
    padding-left: 0px !important;
}
form.form_content .form-check-input {
    background: unset;
    border: 2.67px solid #9C9F9C;
}

.saved_reflection {
    background: #E4E5E1;
    border-radius: 4px;
    padding: 12px;
    height: 640px;
    overflow: auto;
}

.saved_reflection_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #171919;
    padding: 12px 0px;

}

.userFeedback {
    background: #F9F9F7;
    border: 1px solid #D7DAD7;
    border-radius: 6px;
    margin-top: 10px;
    font-size: 14px;
}

.userFeedback h3 {

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #404240;
}

.survey_name_heading h2 {
    color: #404240;

    font-weight: 500;

    line-height: 20px;
    letter-spacing: -0.01em;

}

.survey_name_heading {
    margin: 20px 20px 0px 20px;
}

.detail_para input::placeholder {
    /*font-weight: 500;*/
    font-size: 13px;
    line-height: 18px;
    padding: 20px;
    color: #171919;
}

.detail_para.form-group input {
    border: transparent;
}

.spiritual_pathways2 .form-control:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none
}

.spiritual_pathways2 .form-control::placeholder {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #171919;

}

.detail_para.form-group {
    margin-right: 15px;
    width: 100%;
}

.form_content.survey_creation_container input[type ="radio"] {
    background: #f0efeb;
    border: 1px solid #c7c7c7;
}

.date_heading {
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #9C9F9C;
}

/* neww */
.user_review_para {
    color: #404240;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.03em;
    line-height: 27px;

}

form .form-control.Text_Container::placeholder {
    color: #9C9F9C;
}

.Date {
    color: #9C9F9C;
}

.maximise_img {
    width: 18px;
    height: 18px;
    margin-left: auto;
    margin-right: 0px;
    margin-top: 9px;
}

.tags-input-field:disabled {
    /*background: var(--bs-secondary-bg);*/
    opacity: 1;
    background: #e9ecef;
}

@media screen and (max-width: 991px) {
    .user_information h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        letter-spacing: -0.03em;
        color: #171919;
        margin-top: 12px;
    }

    .user_information p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #404240;
    }

    .option-select-container hr {
        width: 92%;
    }
}


@media screen and (max-width: 767px) {
    .save_survey_container {
        display: block !important;

    }

    .survey-tag-outer {
        margin: 6px 0px !important;
        width: 100% !important;
    }


    .min-value-container {
        margin-right: 0px;
    }

    .survey-name-input {
        margin-bottom: 0px;
        width: 100% !important;
    }

    .survey-name-outer {
        width: 100%;
    }

    .option-select-container {
        margin-right: 0px;
    }

    .tag-outer {
        margin-right: 0px;
    }

    .user_information {
        padding-left: 7px;
    }

    .option-select-container hr {
        width: 100%;
    }

    .option-input-value {
        margin-right: 0px;
    }
    .option_Wrapper input {
        margin-left: 0px;
    }
    .option_Wrapper{
        gap: 10px;
    }

    .user_information h2 {
        font-size: 16px;
    }

    .survey_creation_container {
        padding: 0px;
    }

    .positiveNegative {
        width: 100%;
    }

    .saved_reflection {
        margin-top: 10px;
    }


}

@media screen and (max-width: 600px) {
    .survey-name-share-btn {
        display: block !important;

    }

    .survey-name-input {
        margin-bottom: 10px;
    }

    .share-reflection-lvl-btn {
        display: block !important;
        text-align: center;
    }

    .reflection-lvl-dropdown {
        margin-bottom: 10px !important;
    }

    .survey-title-container {
        justify-content: center;
    }


}