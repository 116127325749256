.attempt-survey-date_display_text h6 {
    text-align: center;
    margin-top: 10px;
    opacity: 0.7;
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}
.attempt-reflection-table-outer{
    margin: 0 15px;
}
.chart-details{
     display: flex;
    justify-content: center;

}
.quote {
    width: 60%;
    margin: auto;
}
.circle-text {
     color: #171919;
    font-size: 30px;
     font-weight: 600;
     font-family: 'General Sans Regular';
 }
.result-display{
     display: flex;
}
.first-bar {

    padding: 12px 20px 1px 20px;
}

.second-attempt {
    padding: 12px 20px 1px 20px;
}

.third-.attempt {

    padding: 12px 20px 1px 20px;
}
.result-display b{
    margin-left: 25px;
    margin-top: 15px;;
}


.result-display {
    display: flex;
}
.Status {
    margin-top: 13px;
    margin-right: 10px;
    font-size: 19px;
    margin-left: auto;
}

.data-content {
    margin: 11px;
}

.data-content h5 {
    font-size: 28px;
}

.attempted-reflection-skeleton-loader-modal .loader-wrapper .loader-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:0px !important;
    padding: 15px;
}

.text-end{
    text-align: end;
}
.surveys-heading{
    display: flex;
    align-items: center;
}
.surveys-heading img{
    width: 10px;
    /*margin-left: 5px;*/
}
.attempt-surveys {
    justify-content: center;
    display: flex;
    align-items: center;
}
.custom_table .table thead .attempt-surveys label {
    font-size: 13px;
    font-weight: 500;
}

.custom_table .table thead .attempt-surveys-heading {
    display: flex;
}


.custom_table .table thead .attempt-surveys img {
    width: 10px;
    margin-left: 5px;
}
.survey-attempt-search-box{
    margin: 0px 15px;
    display: flex;
    height: 46px;
}
.survey-attempt-search-btn {
    /* background: black; */
    border: transparent;
    border-radius: 4px;
    height: 45px;
    min-width: 44px;
}
.survey-answer-detail-heading {
    font-family: "General Sans Regular";
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 11px 11px;
    margin: -1px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #e2e5e0;

}

span.span-result {
    min-width: 100px;
    font-size: 13px;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    line-height: 22px;
}


.selected{
    background: #f4956c !important;
}

.attempt-survey-search-box {
    margin-right: 0px !important;
    padding: 0px 20px 0px 15px;
}
.height{
    height: 41px !important;
}
.btn-height{
    height: 40px !important;
}