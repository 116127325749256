.view-members-link{
    color: #DB4C2D;

    cursor: pointer;
}
.no-data-img {
    border-radius: 15px;
    opacity: 0.6;
}
.no-data-img-outer {
    padding-top: 120px;
}
 .org_page .members-name-filter-input {
    border-color: #d3d8dc !important;
    height: 45px !important;
     width: 25%;
}
span.org-status {
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: black;
    padding: 5px 10px;
    margin-left: auto;
    opacity: 1;
    border-radius: 7px;
}
.org-status.bg-light-green{
    background: #019974 !important;
    color: #fff !important;
}
.org-status.bg-light-red{
    background: #FF4040 !important;
    color: #fff !important;
}
.org-table-loading .loader-wrapper .loader-outer {
    margin-left: -40px;
}
.org-date-picker-outer{
    display: flex;
}
.display_profile_data.wrap-text.cursor-pointer :hover  {
    color: #007bff!important;
}
svg.disable-org-icon {
    height: 20px;
    width: 20px;
}
svg.enable-org-icon {
    height: 25px;
    width: 25px;
    color: green;
}
.date-and-active-filter-outer {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1199px) {
    .org-active-inactive-filter {
        margin-top: 4px;
    }
    .org-table-loading .loader-wrapper .loader-outer {
        margin-left: -20px;
    }
    .org-filter-outer{
        display: block !important;
    }
    .org-filter-date-picker-outer{
        display: block !important;
    }
    .org-filter-srch-btn{
        margin-top: 4px;
    }
    .org-date-picker-outer {
        justify-content: center;
        margin-top: 4px;
    }
    .org-filter-level{
        margin-top: 4px;
    }
}

@media screen and (max-width: 991px) {
    .org-table-loading .loader-wrapper .loader-outer {
        margin-left: 0px;
    }
}
@media screen and (max-width: 938px) {
    .date-and-active-filter-outer{
        display: block;
    }
}

@media screen and (max-width: 528px) {
    .org-date-picker-outer{
        display: block !important;
    }

}

@media screen and (max-width: 450px) {
    .org-heading-outer {
        display: block;
    }
    .org-heading{
        text-align: center;
    }
    .create-sub-org-btn-outer{
        text-align: center;
    }
}

