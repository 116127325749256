.campaign-name-input::placeholder{
    font-size: 12px !important;
    font-weight: 500 !important;
    opacity: .8 !important;
}
.campaign-name-input:focus{
    box-shadow: none !important;
    border: 2px solid #86b7fe !important;
}
.campaign-name-input{
    border-color: #c2c2c2bf !important;
}
.form_inner_content {
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 20px;
    margin-top: 15px;

    padding: 30px 25px;
}
.step_outer{
    height: 32px;
    width: 32px;
    border-radius: 30px;
    background: #e7e4e4;
    text-align: center;
    font-size: 22px;
    line-height: 32px;
}
.cmn_sub_heading{
    color: #171919;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
}
.form_content_outer label{
    color: #404240;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: .5rem;
}
.form_next_btn{
    background: linear-gradient(214.03deg, #fcad80 3.66%, #db4c2d 90.58%);
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    height: 45px;
    line-height: 24px;
    width: 91px;
}
.form_prev_btn{
    background: #e4e4e4;
    border: none;
    border-radius: 8px;
    color: #404240;
    padding: 10px 20px;
    margin-right:10px

}
.scheduling_heading{
    position: relative;
}
.step-container{
    display: flex;
    justify-content: center;
    gap: 7rem;

}
.active_tab{
    color: #EA7954;;
}
h2.scheduling_heading::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 63%;
    top: 50%;
    left: -72%;
    background: #6C6F75;
}

.quill.quill-editor {
    height: 100%;
    min-height: 380px;
}

.custom-quill-editor {
    border-radius: 6px; /* Add rounded corners to the whole editor */
    overflow: hidden; /* Ensure rounded corners are respected */
    border: 1px solid #ddd; /* Optional: Add a border */
    margin-top: 28px !important;
    background: white;
}
.ql-toolbar.ql-snow{
    border-bottom:1px solid #ccc !important;
    border-top:unset !important;
    border-left:unset !important;
    border-right:unset  !important;

}
.quill-editor .ql-container.ql-snow {
     border: unset !important;
}
.schedule_wrapper_box{
    gap:10px
}
.cmn_campaign_Wrapper{
    border: 1px solid #e2e2e2;
    border-radius: 10px !important;
    min-height: 500px;
    background: #F5F5F5;
    padding: 20px;
    height: 100%;
}

/* styles.css */
.time-picker-container {
    position: relative;
    width: 200px;
    margin: 20px auto;
}

.time-picker-wrapper {
    position: relative;
}

.time-picker-input {
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    border: 1px solid #ede6e6;
    border-radius: 6px;
    font-size: 16px;
    background-color: #fff;
}
.time-picker-wrapper{
    width: 100%;
}
.time-picker-input::placeholder {
    color: #aaa; /* Color for the placeholder */
}

.time-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

}
.custom-quill-editor .ql-editor p {
    font-size: 16px !important; /* Set the default font size */
}
.campaign_input_field{
    background: white;
    width: 100%;
    word-break: break-word;
    opacity: .7;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    border: 1px solid #d7dad7;
    border-radius: 8px;
    padding: 4px 9px;
}
.campaign-date-picker{
    border:1px solid #ced4d9;
    padding: 8px;
    height: 38px;
}

.campaign-recipients{
    font-size: 14px;
    border-radius: 3px;
    display: inline-block;
    background: #e5e5e5;
    padding: 0px 6px ;
}
.campaign_input_field.campaign-message {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media screen and (max-width: 1024px){
    .schedule_wrapper_box{
        flex-wrap: wrap;
    }
    .datetime_wrapper{
        flex-wrap: wrap;
    }

}