/* .selectMember_outer{
    background-color: 
    #D1D9E2;
    border-radius: 8px;
} */
.modal-body{
    background: #CFCFD0;
    margin: 0;
    padding: 7px !important;
}
.userEmail {
    margin-top: 14px;
}
.userEmail h2{
    line-height: 20px;
    color: #2E3646;
    font-size: 15px;
}

    .userEmail p {
        color: #9C9F9C;
    }

.selectMember_Form{
    display: flex;
    border-bottom: 1px solid #D1D9E2;
    align-items: center;
}
.member_Container{
    background-color: #ffff;
    padding: 5px 10px;
}
.Image_Container {
    padding: 0px 10px 0px 10px;
    
}
.all_heading{
    color: #5F6D7E;
    font-size: 13px;
line-height: 18px;
padding-left: 15px;
}
.select_member_heading{
font-size: 16px;
line-height: 90%;
letter-spacing: -0.04em;
color: #171919;
font-weight: 600;
}
.select_member_cancelBtn{
    color: #171919;
 background: #E6E9EC;
border: 1px solid #F1F5F8;
border-radius: 5px;
padding: 7px 18px;
}
.select_member_continueBtn{
    color: #F8F9FB;
    background: #2E3646;
   border-radius: 5px;
   padding: 8px 16px;
   border: transparent;

}