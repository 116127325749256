.question {
    font-weight: 500;
    margin-left: 4px;
    font-size: 16px;
    overflow: hidden;
}
.attempted-details {
    border: 2px solid #e2e2e2;
    margin-top: 20px;
    padding: 19px;
    border-radius: 8px;
    position: relative;
}
.answer_survey_body_content{
    padding: 10px;
}
.next-button {
    display: flex;
    align-items: center;
    width: 100%;
}

.next-button button {
    margin-left: auto;
    border: transparent;
    padding: 6px 14px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
}

.range-div {
    white-space: nowrap;
    min-width: fit-content;
    background: rgba(0, 0, 0, 10%);
    font-size: 12px;
    border-radius: 30px;
    padding: 5px 12px;
    text-align: center;
}
.text-question-data {
    padding: 10px;
    border: unset;
    border-bottom: 1px solid;
}
.cmn_bg {
    background: #F5F5F5;
    min-height: 100vh;
}
.custom-circular-bar {
    display: flex;
    align-items: center;
}

.outer-border {
    border: 1px solid #e2e2e2;
    background: #ffffff;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}
.number-data {
    position: relative;
    padding: 9px;
    border-bottom: 1px solid #000;
    display: flex;
    align-items: center;
}
.text-question-data .form-control{
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0px;
    font-size: 14px;
    padding: 0;
    border: transparent;
    font-weight: 400;
    font-family: 'Inter';

}
.text-question-data .form-control:focus{
    box-shadow: none;

}
.number-data .form-control{
    border: unset;
    font-size: 14px;
}
.number-data .form-control:focus{
    box-shadow: none;
}

.header-attempt {
    background: #FFFFFF;
    padding: 10px;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 10000;
    align-items: center;
    justify-content: space-between;


}

.clear_btn button {
    border: transparent;
    font-size: 14px;
    padding: 5px 18px;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    font-family: 'Inter';
    align-items: center;
}

.public-survey {
    width: 100%;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 10%);
    margin-bottom: 10px;
    padding: 8px;
    margin-top: 10px;
}
.attempt_wrapper{
    max-width: 660px;
    width: 100%;
    padding-top: 60px;
    margin: auto;
}
.section-custom {
    width: 100%;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 10%);
    padding: 12px;
}



.attempted-details h5 {
    position: absolute;
    top: -10px;
    font-size: 17px;
    background: #fff;
}
.section-dev {
    border: 2px solid #e2e2e2;
    margin-top: 20px;
  border-radius: 8px;
    padding: 10px;
    position: relative;
}

.section-dev h5 {
    position: absolute;
    top: -12px;
    font-size: 17px;
    background: #fff;
}

.custom-circular-bar .CircularProgressbar .CircularProgressbar-path {
    stroke: #000;
}
.question-title h5 {
    ont-size: 18px;
    font-weight: 400;
    font-family: 'Inter';
    line-height: 28px;
    margin-bottom: 16px;
}

.option-content {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.options {
    word-break: keep-all;
    flex-grow: 1;
    margin-left: 10px;
    font-size: 15px;
    overflow: auto;
}
.range-value-input::placeholder {
    opacity: .6;
}
.question-numbering {
    font-weight: 500;
    font-size: 16px;
}
.question-container {
    display: flex;
    margin-right: 91px;
    padding: 5px;
    border-radius: 6px;
}
.options-list-heading {
    border-radius: 6px;
    padding: 0 5px 5px 5px;
}
.attempt-option-select-container {
    padding-top: 3px;
    padding-bottom: 4px;
}
.answer-survey-question-card {
    background: #f9f9f7;
    border: 1px solid #D7DAD7;
    border-radius: 6px;
    font-size: 14px;
    box-shadow: 1px 2px 10px #b1b1b17a;
    margin: 16px 10px;
    min-height: 200px;
}
.option-text-container{
    margin-right: 91px;
}
.answer-survey-heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -5px;
    border-bottom: 1px solid #d7dad7;
    font-size: 20px;
    font-weight: 450;
    padding: 5px 5px 5px 15px;
    background: #f9f9f7;
    margin-right: -5px;
    border-radius: 4px 5px 0px 0px;
}
.margin-left{
    margin-left: 10px;
}
.answer-question-option-heading {
    margin-left: 15px;
    padding: 0 0 0 0;
    display: flex;
    align-items: center;
    width: 87%;
    font-weight: 600;
}
.required-text-outer {
    background: #ffc107;
    border: initial;
    border-radius: 0;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 8px;
    position: absolute;
    top: 0;
    right: 17px;
    height: 33px;
    color: white;
}
.public-survey .required-text-outer {
    background: #ffc107;
    border: initial;
    border-radius: 0;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 8px;
    position: absolute;
    top: 0;
    right: 17px;
    height: 28px;
    color: white;
}

.public-survey .required-text-outer:before{
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: 16px solid #ffc107;
    bottom: -16px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
}
.strategy-txt-border-radius{
    border-radius: 8px  8px 0px 0px !important;
}
.rotate-90deg{
    rotate: 90deg;
}
.strategy-txt{
    color:white;
    border-radius: 8px;
    padding: 4px 15px;
}
.strategy-detail{
    border:1px solid #D7DAD7;
    box-shadow:1px 2px 10px #b1b1b17a;
    padding: 4px 15px;
    border-radius: 0px 0px 8px 8px;
}
.public-survey-strategy-txt{
    border:1px solid #D7DAD7;
}
.required-text-outer:before{
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 16px solid #ffc107;
    bottom: -16px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
}
.ToolTip{
    box-shadow: 2px 2px 4px black;
}

.text-response-answer-outer {
    margin: 3px 15px 0 15px;

}
.number-answer-outer {
    display: flex;
    margin: 3px 0 4px 15px;
    align-items: center;
}
.number-range {
    font-size: 18px;
    font-family: 'General Sans Medium';
    opacity: .8;
    white-space: nowrap;
    min-width: fit-content;
}
.text-response-answer {
    min-height: 100px;
    padding: 8px 15px;
    border-radius: 6px;
    border: 1px solid #e6e7e5;
    font-size: 15px;
}
@media screen and (max-width: 767px) {
    .number-answer-input {
        width: 100%;
    }
}