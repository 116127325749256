.pathways_container {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px;
}

.main_results_container {
    background: #F0EFEB;
    border-radius: 4px;

}

.next_btn {
    width: 96px;
    height: 46px;
    background: #F3F2EF;
    border: 1px solid #D7DAD7;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #404240;
}

.next_btn_container {
    position: relative;

}

.right_arrow {
    position: absolute;
    right: 7px;
    top: 14px;
    height: 20px;
    width: 20px;

}
.custom_text {
    position: absolute;
    width: 34%;
    padding-top: 66px;
}
.custom_text h5{
    font-size: 18px;
    line-height: 18px;
    color:#171919;
    text-align: center;
    font-weight: 600px;
}
.custom_text p{
    color: #9C9F9C;
    font-size:13px;
    line-height: 18px;
    text-align: center;
}

.risk_container {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.risk_heading {
    background: #F8B0AC;
    border-radius: 4px;
    text-align: center;
}

.low_risk_heading {
    background: #FBD9A7;
    border-radius: 4px;
}

.good_risk_heading {
    background: #CBE7B7;
    border-radius: 4px;
}

.cmn_risk_heading {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #171919;
    padding: 11px 0px;
}

.circle {
    width: 212px;
    height: 212px;
    border-radius: 50%;
    border: 20px solid rgb(23 25 25 / 16%);
    margin-bottom: 10px;
    margin-top: 12px;
}

.circle h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #171919;
    padding-top: 3rem;
}

.circle p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #9C9F9C;
}

.user_container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E5E1;
}

.user_name h2 {

    font-size: 15px;
    line-height: 22px;
    color: #171919;

}

.user_name h3 {

    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #9C9F9C;

}

.userimg {
    height: 32px;
    width: 32px;
}

.user_outer_survey {
    background: #F9F9F7;
    border-radius: 4px;
    flex-grow: 1;
    padding: 20px 20px 0px 20px;

}

.good_risk {
    background: #CBE7B7;
    border-radius: 4px;
}

.low_risk {
    border-radius: 4px;
}

.spiritual {
    display: flex;
    align-items: center;
}

.spiritual h2 {
    
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #171919;
    border-right: 1px solid #D7DAD7;
    padding-right: 20px;
}

.spiritual h3 {
    padding-left: 20px;
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #171919;
    margin-top: 12px;
    opacity: 0.7;
}
.circile_custom {
    display: flex;
    justify-content: center;
}
.cmn_userstyle {
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 500;
    padding-left: 13px;
    margin-top: 2px;
}

@media screen and (max-width:991px) {

    .circle {
        border-radius: 50%;
        border: 20px solid rgb(23 25 25 / 16%);
        justify-content: center;

    }

    .spiritual h2 {
        font-family: 'General Sans Bold';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 38px;
        color: #171919;
        border-right: unset;
        padding-right: 20px;
    }

    .next_btn {
        width: 82px;
        height: 39px;
        background: #F3F2EF;
        border: 1px solid #D7DAD7;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #404240;
    }


    .right_arrow {
        position: absolute;
        right: 7px;
        top: 13px;
        height: 15px;
        width: 15px;
    }

    .circle h2 {

        font-weight: 600;
        font-size: 26px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #171919;
        padding-top: 54px;
    }

    .circle p {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        color: #9C9F9C;
    }

    .cmn_userstyle {

        font-family: 'General Sans Medium';
        font-style: normal;
        font-weight: 500;
        padding-left: 4px
    }

    .user_outer_survey {
        background: #F9F9F7;
        border-radius: 4px;
        flex-grow: 1;
        padding: 19px 20px 0px 6px;
    }

    .user_name h3 {
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        color: #9C9F9C;
    }
}

@media screen and (max-width: 767px) {
    .circle {
        border-radius: 50%;
        border: 20px solid rgb(23 25 25 / 16%);
        justify-content: center;
        width: 150px;
        height: 150px;
        margin-top: 0;
    }

    .circle h2 {
        font-weight: 600;
        font-size: 17px;
        text-align: center;
        letter-spacing: -0.03em;
        color: #171919;
        padding-top: 18px;
    }

    .spiritual h2 {
        font-family: 'General Sans medium';
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #171919;
        padding-right: 20px;
    }

    .spiritual h3 {
        padding-left: 4px;
        font-family: 'General Sans medium';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #171919;
        border-right: unset;
        margin-top: 6px;
    }

    .user_outer_survey {
        background: #F9F9F7;
        border-radius: 4px;
        flex-grow: 1;
        padding: 0px 20px 0px 6px;
    }

    .spiritual {
        display: block;
    }

    .next_btn {
        width: 87px;
        height: 39px;
        background: #F3F2EF;
        border: 1px solid #D7DAD7;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #404240;
        margin-top: 32px;
    }

    .right_arrow {
        position: absolute;
        right: 7px;
        top: 44px;
        height: 15px;
        width: 15px;
    }
}

/* @media screen and (max-width: 375px) {
    .circle {
        border-radius: 50%;
        border: 20px solid rgb(23 25 25 / 16%);
        justify-content: center;
    
        width: 150px;
        height: 150px;
    }

} */