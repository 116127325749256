.react-tabs__tab{
    padding: 6px 15px !important;
    /*font-weight: 500;*/
    margin-right: 6px;
    background: #F5F5F5;
    border-radius: 8px 8px 0px 0px;
    border-color: #d1d1d1 !important;
    bottom: 0px !important;
    font-size: 14px;
}
.react-tabs__tab--selected{
    border-radius: 8px 8px 0px 0px !important;
    color: white !important;
}
i.fa.fa-solid.fa-arrow-left{
    color: white;
}
.bg-orange{
    background: #FF7F50 !important;
}
.bg-green{
    background: #019974 !important;
}
.campaign-status-text{

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    padding: 5px 10px;
    margin-left: auto;
    border-radius: 7px;

    font-family: 'General Sans Regular';
    opacity: 1;
    color: #fff !important;
}

span.sub-org-back-btn {
    margin-right: 7px;
    border-radius: 7px;
    padding: 1px 0px 1px 9px;
}

.create-sub-org-btn-outer {
    text-align: center;
}
div.react-tabs__tab-panel--selected{
    border-top: unset !important;
}
.sub-org_heading_outer {
    display: flex;
    align-items: end;
    justify-content: start;
    border-radius: 4px;
    margin-bottom: .25rem;
}
.sub-organization-details{
    display: flex;
    padding-left: 0;
}
.sub-org-data {
    color: #DB4C2D;
    font-size: 19px;
    cursor: pointer;
}
.sub-org-cmn-heading{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    color: #171919;
}
.react-tabs__tab-list{
    margin: 0 !important;
    border-bottom: none !important;
}
li.nav-item {
    cursor: pointer;
}
.react-tabs__tab-panel--selected{
    background-color: white !important;
    border: 1px solid #aaaaaa !important;
    border-radius: 0 0 .375rem .375rem;
}
.react-tabs__tab:focus::after{
    display: none;
}
.react-tabs__tab--selected:hover {
     isolation: isolate;
     border-color: #aaaaaa !important;
    color: black;
}
.members-tab {
    padding: 20px 12px;
}

.sub-org-members-overall-result-table {
    margin: 20px 12px;
}
.nav-tabs{
    border-bottom: 0px !important;
}

@media screen and (max-width:530px) {
    .react-tabs__tab--selected {
        border-radius: 5px 5px 5px 5px !important;
        border-bottom: 1px solid #aaaaaa !important;
        bottom: 4px !important;
    }
    .react-tabs__tab{
        bottom: 4px !important;
    }
    .react-tabs__tab-panel--selected {
        border-radius: 0.375rem 0.375rem 0.375rem 0.375rem !important;
    }


}