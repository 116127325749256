.reset_form {
    background: #F9F9F7;
    border-radius: 4px;
    padding: 15px;
}

.modal_footer {
    background: #F9F9F7;
}

.userInfo_label {
    color: #404240;;
}
.select_role {
    font-size: 14px;
    color: #171919;
}

.userInfo_input {
    margin-top: 6px;
}
.add-member-modal-btns {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}