
.chart-data {
    display: flex;
    flex-wrap: wrap;
    margin: 5px;
}
.edit-button{
    background: unset !important;
    color: black !important;
    padding: 0px !important;
    border: unset  !important;
}
.edit-button .dropdown-menu.show {
    border: none !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 15%);
}
.edit-button .dropdown-item.active, .dropdown-item:active {
    background: unset !important;
    color: black;
}
.div-chart {
    height: 420px;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    border:1px solid #e2e2e2;
    padding: 10px;
    display: flex;
    justify-content: center;
}
.custom-report .form-group label {
    color: #000;
}

.graph-select .form-group {
    display: flex;
}
.report-title input {
    height: 51px;
}
.report-description textarea {
    height: 51px;
    padding: 10px;
}
.reportData {
    display: flex;
    margin-left: 10px;
}
.div-chart .my__loader {
    position: static;
}
.reports-x-y-fields{
    margin-top: 15px;
}

/* Report Graph css*/
.graph-outer-Line{
    position: relative;
    width: 600px;
    height: 400px;
    margin-top :25px;
}
.graph-outer-Line-dropDown{
    position: absolute;
    padding-top: 10px;
     top: 39px;
    right: -70px;
    transform: translate(-50%, -50%);
}
.graph-outer-Line-report-name{
     position: absolute;
    top: -26px;
    left: 10px;

}
.graph-outer-Line-icon{
    position: absolute;
    top: -23px;
    right: 0px;

}
.graph-outer-Line-x {
    display: flex;
    justify-content: center;
    font-size: 13px
}
.graph-outer-Line-y {
    top: 50%;
    position: absolute;
    left: 0px;
    transform-origin: top left;
    transform: rotate(-90deg) translate(-50%, 0px);
    width: 100%;
    text-align: center;
    font-size: 13px;

}
.graph-outer-Line-icon i{
    font-size: 18px;
    cursor: pointer;
}

/*Pie Chart*/
.graph-outer{
    position: relative;
    height: 350px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.graph-outer-Pie-dropDown{
    position: absolute;
    padding: 10px;
    top: 0px;
    right: -70px;
    transform: translate(-50%, -50%);
}
.report-data-not-available-text{
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.report-details {
    display: flex;
    margin-top: 10px;
    padding:0px 10px;
}
.graph-outer-Pie-report-name{

}
.chart-div-color{
    background: #ffff;
   border-radius: 4px;
    border:1px solid #e2e2e2;
    height: 100%;
}
.graph-outer-Pie-icon{
    margin-left: auto;
    /*position: absolute;*/
    /*top: -20px;*/
    /*right: 0px;*/

}
.graph-outer-Pie-icon i{
    font-size: 18px;
    cursor: pointer;
}
/* End of Pie Chart*/

/*start of donut chart*/
.graph-outer-donut{
    position: relative;
    height: 480px;
    display: flex;
    justify-content: center;
}
.graph-outer-donut-dropDown{
    position: absolute;
    padding: 10px;
    top: 20px;
    right: -70px;
    transform: translate(-50%, -50%);
}

.graph-outer-donut-icon i{
    font-size: 18px;
    cursor: pointer;
}

/*end of donut chart*/



/*Bubble chart start css*/

.graph-outer-bubble{
    position: relative;
    width: 600px;
    height: 400px;
    margin-top :20px;
}

.graph-outer-bubble-dropDown{
    position: absolute;
    padding: 10px;
    top: 20px;
    right: -70px;
    transform: translate(-50%, -50%);
}


.graph-outer-bubble-report-name{
    position: absolute;
    top: -3px;
    left: 10%;
    transform: translate(-50%, -50%)

}
.graph-outer-bubble-icon{
    position: absolute;
    top: -19px;
    right: 0px;
}
.graph-outer-bubble-icon i{
    font-size: 18px;
    cursor: pointer;
}

/*end of css Bubble */

/*Bar chart  css */

.graph-outer-bar{
    position: relative;
    width: 600px;
    height: 400px;
    margin-top :20px;
}

.graph-outer-bar-x {
    display: flex;
    justify-content: center;
    font-size: 13px
}
.graph-outer-bar-y {
    top: 50%;
    position: absolute;
    left: 0px;
    transform-origin: top left;
    transform: rotate(-90deg) translate(-50%, 0px);
    width: 100%;
    text-align: center;
    font-size: 13px;

}
.graph-outer-bar-dropDown{
    position: absolute;
    padding: 10px;
    right: -70px;
    transform: translate(-50%, -50%);
}

.graph-outer-bar-report-name{
    position: absolute;
    top: -6px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%)

}
.graph-outer-bar-icon{
    position: absolute;
    top: -20px;
    right: 0px;
}
.graph-outer-bar-icon i{
    font-size: 18px;
    cursor: pointer;
}
/*end of Bar chart  css */


/*End of report css */


.custom-border{
    border-right: 1px solid #000;
}
.graph-select .form-group label {
    width: 64%;
    margin-top: 10px;
    color: #000;
}
.report-criteria h5 {
    font-size: 18px;
}
.bar-height{
    height: 370px;
}
.div-chart-test {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 60vh;
    border-radius: 4px;
}
.graph-select .form-group select {
    font-size: 14px;
}

.selected-card {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
}
.reportData button{
    margin-left: auto;
    border: transparent;
    border-radius: 8px;
    padding: 8px 17px;
    min-width: 150px;
}

ul.custom_dropdown {
    padding: 0px;
    background: #fff;
    margin: 0px;
    border-radius: 8px;
    box-shadow: 0px 0px  5px rgba(0,0,0,15%);
}
.share-report {
    display: flex;
    align-items: center;
}
.Reflection-graph i {
     margin-left: auto;
 }
.bg-light-red{
    background: #ff000080 !important;;
}
.bg-light-green{
    background: #c2f7c2 !important;
}
.bg-light-orange{
    background: #ff710078 !important;;
}
.share-report button {
    margin-left: auto;
    min-width: 100px;
    padding: 9px 7px;
    font-size: 15px;
    border-radius: 8px;
    border: transparent;
}
.display-chart{
    width: 50%;
    background: white;
    padding: 10px;
    border-radius: 8px;
     border: 1px solid #e2e2e2;
}
.report-details p {
    font-size: 12px;
}
ul.custom_dropdown li button {
    background: transparent;
    border: transparent;
    padding: 6px 10px;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    width: 100%;
    min-width: 120px;
}

ul.custom_dropdown li:last-child button {
    border: unset;
}
.report-data{
     position: relative;
}
.report-data h1{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: calc(50% - 10px);
    margin: 5px;

}

@media screen and (max-width: 1450px) {
    .graph-outer-Line {
        position: relative;
        width: 520px;
        height: 400px;
        margin-top: 25px;
    }
    .bar-height{
        height: 280px;
    }
    .graph-outer-bar {
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: 25px;
    }
    .graph-outer-bubble {
        position: relative;
        width: 520px;
        height: 400px;
        margin-top: 25px;
    }
    .graph-outer-Pie {
        position: relative;
        width: 512px;
        height: 301px;
        margin-top: 25px;
    }


    }


@media screen and (max-width: 1400px) {

    .chart-data {
        display: flex;
        flex-wrap: wrap;
        margin: 5px;
    }

    .div-chart {
        height: 336px;
        background: #fff;
        border-radius: 4px;
        width: calc(100% - 10px);
        margin: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .graph-outer-donut{
        position: relative;
        width: 600px;
        height: 300px;
        display: flex;
        padding:10px;
        justify-content: center;
        margin-top :20px;
    }

    .graph-outer-Line {
        position: relative;
        width: 448px;
        height: 300px;
        margin-top: 25px;
    }


}

@media screen and (max-width: 1200px) {
    .div-chart {
        height: 303px;
        background: #fff;
        border-radius: 4px;
        width: calc(100% - 10px);
        margin: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .graph-outer-donut {
        position: relative;
        width: 594px;
        height: 270px;
        display: flex;
        padding: 10px;
        justify-content: center;
        margin-top: 20px;
    }
    .graph-outer-Line {
        position: relative;
        width: 400px;
        height: 230px;
        margin-top: 25px;
    }
}

@media screen and (max-width: 1025px) {

    .chart-data {
        display: block;
        margin: 5px;
    }

    .div-chart {
        height: 420px;
        background: #fff;
        border-radius: 4px;
        width: 100%;
        margin: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
    }


}

.graph-outer-Pie-dropDown_tabular{
    position: absolute;
    padding: 10px;
    top: 0px;
    left: 30px;
    z-index: 9999;
}
