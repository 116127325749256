.role_data table thead {
    border-radius: 10px;
    vertical-align: middle;
    background: #F9F9F7;
    border-bottom: 1px solid #D7DAD7;
}

.role_data table tbody {
    background-color: #ffff;
}

.role_data table tbody tr {
    border: 1px solid #E3E5E1;
}

.role_data table {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    margin-bottom: 0;
}

.role_data table thead th {
    padding: 13px 17px;
    width: 0;

}

.role_data table tbody tr th {
    padding: 13px 17px;
    width: 18%;
}

.role_data table thead th {
    padding: 13px 17px;

}

.cursor {
    cursor: pointer;
}

.cursor:hover {
    color: #0a58ca;
}
.roles-table-loading .loader-wrapper .loader-outer {
    justify-content: flex-start !important;
    margin: 0 0 0 20px !important;
}
.roles-table-loading .loader-wrapper .loader-outer span:first-child{
    margin-right: 80px !important;
    width: 120px;
}
.roles-table-loading .loader-wrapper .loader-outer span:last-child{
min-width: 170px !important;

}
.roles-table-loading .loader-wrapper .loader-outer span:last-child span{
    width: 100% !important;
}
