.new_survey_outer{
    display: flex;
    justify-content: space-between;
    padding: 15px 13px;
    background-color: #E1DAD8;
    border-radius: 8px;

}

.survey_cancel_btn{
    margin-right: 10px;
    background: #F3F2EF;
    border: 1px solid #D1D9E2;
    color: #5F6D7E;

}
.cross_icon img{
    height: 12px;
    width: 12px;
}
.survey_save_btn{
    color: #F8F9FB;
    border: none;
}
.cmn_create_survey_btn{
    padding: 8px 8px;
    width: 64px;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
}
.question_container h2{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #2E3646;
}
.question_container p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #5F6D7E;
}
.add_survey h2{
    padding: 12px 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #5F6D7E;
    border-bottom: 1px solid #D1D9E2;
}
.add_survey{
    border: 1px solid #D1D9E2;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px 10px 0px 0px;
    margin-top: 10px;
}

.radio_button{
    position: absolute;
    left: 10px;
    top: 6px;
}

button#dropdown-basic-button {
    background-color: #F8F9FB;
    border: 1px solid #5F6D7E;
    border-radius: 6px;
    width: 164px;
    height: 41px;
    color:
            #5F6D7E;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
}
.input_list{
    padding: 21px 0px 0px;
}
.input_list li{
    display: flex;
    align-items: center;

}
.input_list li:nth-child(2){
    margin-top: 10px;
}
.form_text{
    background-color: transparent;
    border: transparent;
    border-bottom:1px solid #D1D9E2;
    width: 100%;
    border-radius: unset;
    padding: 0.375rem 0px;
}
.dropdown-toggle::after{
    /*content: url('src/images/Vector.png');*/
    vertical-align: unset;
    border-top: unset;
    border-right: unset;
    border-bottom: unset;
    border-left: unset;
    padding-left: 21px;
}
input.form_text.form-control::placeholder {
    color: #5F6D7E;
    font-style: normal;
    /*font-weight: 600;*/
    font-size: 15px;
    line-height: 22px;
    font-size: "General Sans Medium";
}
.list_container{

    padding: 26px;
}
.user_response{
    text-indent: 27px;
}
input.radio_button {
    margin-top: 5px;
}
form .user_response::placeholder{

color: #5F6D7E;
    font-style: normal;
    /*font-weight: 600;*/
    font-size: 15px;
    line-height: 22px;
    text-indent: 20px;
    padding-left: 10px;
    font-size: "General Sans Medium";
}
.cross_icon{
    width: 30px;
    height: 30px;
    border: 1px solid #D1D9E2;
    border-radius: 5px;
    background-color: #E6E9EC;
    margin-left: 10px;
    padding: 0px 9px;
}
.sort_icon{
    width: 30px;
    height: 30px;
    padding: 0px 10px;
}


.inputtext_wrapper {
    width: 100%;
}
.input_radio{
    width: 100%;
    margin-top: 6px;
    position: relative;
}
.crossAndSort_img{
    display: flex;
}
.add_answer_btn{
    color: #F8F9FB;
    height: 40px;
    width: 131px;
    margin-top: 10px;
    border: transparent;
}
.plus_img_container span {
    padding-left: 7px;}
.plus_img_container img{
    height: 12px;
    width:12px;
}
.add_question_btn{
    width: 141px;
    height: 40px;
    color: #F8F9FB;
    border: transparent;
    margin-top: 10px;

}

.input_Field::placeholder{
    color: #7C8B9D;
}
.input_Field{
    text-indent: 10px;
}
@media screen and (max-width:767px){
    .new_survey_outer{
        display: block;
        text-align: center;
    }
    .question_container h2{
        font-size: 20px;
    }
    .crossAndSort_img {
        margin-top: 10px;

    }
    .cross_icon{
        margin-left: 0px;
    }
    ul.input_list {
        padding: 0px;
    }

    .dropdown-toggle::after {
        padding-left: 4px;
    }

    button#dropdown-basic-button {
        margin-top: 10px;
        margin-left: 0px;
        width: 143px;
        height: 40px;
    }
    .list_container{
        padding: 10px 0px 0px;

    }
    .add_question_btn {
        width: 130px;
        height: 37px;}
    .input_list li {
        display: block;
    }
}