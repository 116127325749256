.upper_result {
    display: flex;
}

.upper_result h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
}
.upper_result .smallbtn {
    width: 80px;
    height: 36px;
    right: 373px;
    top: calc(50% - 36px/2 + 135px);
    border: 1px solid #D7DAD7;
    border-radius: 5px;
}
.upper_result h6 {
    margin-left: 12px;
    margin-top: 10px;
    font-weight: 600;
    color: #171919;
}
.upper_result .smallbtn span {
    font-style: normal;
    font-weight: 600;
    padding: 0;
    font-size:14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: -0.03em;
}
.result_data table tbody tr {
    border: 1px solid #E3E5E1;
}
.result_data table{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    margin-bottom: 0;
}

.show_header_data{
       
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.03em;
        color: #171919;

}
.result_data table thead th {
    padding: 13px 12px;
    
}

.result_data table tbody tr th {
    padding: 13px 17px;
}
.show_header_data_first_td{
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #171919;

}
.result-table-heading{
border: 1px solid #e2e5df;
}
.result_data  a {
    text-decoration: none;
    color: #171919;
}
.show_header_data_other_td{
    font-family: 'General Sans Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    line-height: 20px;
    letter-spacing: -0.03em;
    color: #171919;
    vertical-align: middle;

}
.show_header_data_other_td span{
    opacity: 0.7;
}
.result_data table thead{
    border-radius: 10px;
    vertical-align: middle;
    background: #F9F9F7;
    border-bottom: 1px solid #D7DAD7;
}
.result_data table tbody{
    background-color: #ffff;
}

.upper_result button {
    margin-left: auto;
    border: transparent;  
    border-radius: 8px;
   
}
.upper_result span {
    font-family: 'General Sans Medium';
    padding: 5px 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #ffff;
}
.result_data .table th.high_risk {
    /* width: 10%; */
    text-align: center;
    

}
.display_date{
    width: 15%;
}
.button_data{
    text-align: center;
}
.table_buttons{
    text-align: center;
    font-size: 14px;
    font-family: 'General Sans Medium';
    font-style: normal;
    color: #171919;


}

.result_header_data h6 {
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #171919;
}

.div_search p {
    position: absolute;
    top: 5px;
    left: 12px;
}

.upper_result .div_search {
    margin-left: auto;
    position: relative;
}

.div_search input {
    width: 354px;
    height: 40px;
    background: #F8F9FB;
    border: 1px solid #5F6D7E;
    border-radius: 6px;
    text-indent: 33px;
}

.result_data .table img {
    width: 11px;
    margin-left: 4px;
}
.result_data .table th.high_risk button {
    background: rgba(248, 176, 172, 0.3);
    border: 1px solid #F8B0AC;
    color:#171919;
    font-size: 14px;
    border-radius: 5px;
    padding: 4px 8px;
    font-weight: 600;

}
td.show_header_data_other_td.high_risk button {
    background: #FBD9A7;
    border: 1px solid  #FBD9A7;
    color: #171919;
    font-size: 13px;
    width: 80px;
}

td.show_header_data_other_td.low_risk button {
    background: #F8B0AC;
    border: 1px solid  #F8B0AC;
    color: #171919;
    font-size: 12px;
    width: 80px;
}

.result_data .table th.low_risk {
    /* width: 10%; */
    text-align: center;
}
.result_data .table th.low_risk button {
    background: rgba(251, 217, 167, 0.3);
    border: 1px solid #FBD9A7;
    color:#171919;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 5px;
}

td.show_header_data_other_td.good button {
    background: #CBE7B7;
    border: 1px solid #CBE7B7;
    color: #171919;
    font-size: 14px;
    min-width: 80px;
}

.result_data .table th.good {
    /* width: 10%; */
    text-align: center;
}
.result_data .table th.good button {
    background: rgba(203, 231, 183, 0.3);
    border: 1px solid #CBE7B7;
    color:#171919;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 5px;
}

@media screen and (max-width:767px){
    .upper_result span{
        
        font-size: 12px;
    }
    .upper_result button{
        height: 40px;
    }
    .add_new_reflection_btn{
        height: 40px;
        width:140px
    }
    .upper_result {
       display: block;
       text-align: center;
    }
}
