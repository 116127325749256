.create-criteria{

    display: flex;
    justify-content: center;
}
.create-criteria button{
    min-width: 150px;
    padding: 7px 9px;
    border: none;
    margin-left: auto;
    font-size: 15px;
    border-radius: 10px;
}
.selection-data {

    padding:10px;
}
.criteria-field {
    display: flex;
}
.add-action-delete {
    margin-left: 7px;
    margin-top: 7px;
    cursor: pointer;
}
.add-action {
    margin-top: 7px;
    margin-left: 6px;
    cursor: pointer;
}
.operand-card {
    background: #ffff;
    border-radius: 4px;
    margin-bottom: 11px;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid #e2e2e2;
}
.type-data {
    margin-top: 8px;
    display: flex;
}

.type-data select {
    width: 62%;
}
.operand-data {
    display: flex;
}

.operand-data label {
    width: 33%;
}

.operand-data select.form-select {
    width: 61%;
    height: 40px;
    font-size: 13px;
}
.criteria-field select {
    font-size: 13px;
    height: 40px;
}
.show-border-down-side {
    height: 1px;
    background: #e2e2e2;
}
.action-enum-data {
    display: flex;
    margin-top: 16px;
    justify-content: space-around;
}
.action-submit{
     display: flex;
    justify-content: end;
    margin-top: 10px;
}
.custom-modal-body label{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 3px;
    margin-top: 3px;
    display: flex;
    align-items: center;
    color: #000;
}
.modal-xl {
    --bs-modal-width: 1100px !important;
}
.email-check-action .form-check {
     display: flex;
     min-height: 1.5rem;
     padding-left: 1.5em;
     margin-bottom: 1.125rem;
 }
.custom-border{
    border-right: 1px solid #e2e2e2;
}

.email-check-action .form-check label {
    margin-left: 10px;
}
span.email-add-icon {
    margin-left: 7px;
    font-size: 11px;
    cursor: pointer;
    margin-top: 5px;
}
.send-custom-email {
    margin-top: 10px;
}

.send-custom-email .form-control {
    height: 37px;
    font-size: 14px;
}
.first-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
}

.op-label {
    display: flex;
    margin-left: auto;
}

.op-label .form-select {
    height: 28px;
    font-size: 11px;
}
.op-label label {
    margin-right: 16px;
    justify-content: end;
}

/*.first-header .form-select {*/
/*    height: 33px;*/
/*    width: 15%;*/
/*    margin-left: auto;*/
/*    font-size: 14px;*/
/*}*/

.first-header h5 ,.frequency h5{
    width: 50%;
    margin-bottom: 0;
    font-size: 16px;
}
.prepare-action {
    display: flex;
   padding: 10px;
}
 .select-action-card{
     background: #ffff;
     padding: 0px;
     margin-top: 10px;
     margin-left: 10px;
     border-radius: 4px;
     border: 1px solid #e2e2e2;


 }
.email-check-action{
    padding:5px;
}
.assigned-mentor-select {
    margin-left: 10px;
    margin-top: 10px;
}
.prepare-action label {
    width: 64%;
    font-size: 16px;
}


.prepare-action select {
    height: 37px;
    font-size: 13px;
}

.custom-select .css-b62m3t-container {
    margin-top: 7px;
    margin-left: -1px;
    margin-right: 4px;
}
.criteria-field select {
    font-size: 14px;
    height: 40px;
}

.custom-margin .form-control {
    height: 40px;
    font-size: 14px;
}
label.mb-2.ms-2 {
    font-size: 16px;
}
.add-new-operand button {
    border: transparent;
    padding: 8px;
    border-radius: 4px;
    font-size: 13px;
    margin-top: 12px;
}
.add-new-operand{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.show-Action{
    cursor: pointer;
}
.selection-Criteria-type{
     width: 50%;
}
.selection-data .custom-margin{
    margin-left: 5px;
    width: 100%;
}
.selection-Operator-type{
    width: 50%;
}
.action-card {
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
}
.action-data{
    display: flex;
}
.check-icon{
    font-size: 15px;
    color: green;
}
.cross-icon{
    font-size: 15px;
    color: red;
}
.selection-operator-type {
     display: flex;
    justify-content: space-between;
}
.add-filter {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 12px;
    padding: 3px;
    font-weight: 600;
    border-right: 4px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
}
.info-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;

}
.custom-card-color.form-select:focus {
     border-color: #ffff;
    outline: 0;
    box-shadow: none;
}
.add-operand-delete {
    margin-left: 5px;
    margin-right: 10px;
    margin-top: 7px;
}
.operand-name .form-control {
    height: 51px;
    font-size: 12px;
}
.operand-name {
    padding: 10px;
    width:50%
}

.operand-name .form-control {
    height: 51px;
    font-size: 13px;
}

.operand-description .form-control {
    height: 51px;
    font-size: 13px;
    padding-top: 15px;
}
.operand-selection {
    display: flex;
    margin-bottom: 8px;
}

.operand-description {
    padding: 10px;
    width:50%
}
.custom-card-color.form-control:focus {
    background-color: transparent;
    border-color: #ffff;
    outline: 0;
    box-shadow: none;
}
.action-icons {
    display: flex;
}

.action-icons .fa.fa-play-circle {
    font-size: 15px;
}
.frequency{
    display: flex;
    align-items: center;
}
.frequency select{
    width: 50%;
    height: 32px;
    font-size: 13px;
    margin-left: auto;
}
.filter-data {
    display: flex;
    margin-left: 4px;
    margin-right: 12px;
    align-items: center;
    margin-top: 5px;
}
.add-operand{
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-size: 17px;
    margin-right: 6px;
}
.add-data {
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 5px;

}
