.survey-details-outer {
    background: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 10%);
    padding: 10px;
    border-radius: 14px;
}
.pie-chart{
    position: relative;;
}
.section-result {
    background: #fff;
    border-radius: 8px;
    border: 2px solid #e2e2e2;
    position: relative;
    margin-top: 10px;
}


.section-result h5 {
    font-size: 17px;
    position: absolute;
    top: -10px;
    background: #fff;
}
.data-section {
    border: 2px solid #e2e2e2;
    padding: 10px;
    border-radius: 8px;
    position: relative;
}

.data-section h5 {
    position: absolute;
    top: -12px;
    background: #f5f5f5;
    font-size: 18px;
}
/*.bg-light-green{*/
/*    background: #c2f7c2 !important;*/
/*}*/
/*.bg-light-orange{*/
/*    background: #ff710078 !important;;*/
/*}*/

.question-text {
    word-break: break-all;
    font-weight: 500;
}

.answer-details-options {
    word-break: break-all;
}
/*.bg-light-red{*/
/*    background: #ff000080 !important;;*/
/*}*/
.disable-range-text{
    font-family: 'General Sans Regular';
    opacity: .8;
}
.number-answer-container{
    display: flex;
    align-items: center;
}
.question-range {
    min-width: 150px;
    font-family: 'General Sans Regular';
    font-size: 15px;
    text-align: end;
}

.text-response-answer-result {
    border-radius: 5px;
    padding: 5px 10px;
    word-break: break-all;
}

.disable-text {
    font-family: 'General Sans Regular';
    opacity: .8;
}
.text-response-answer-result-outer {
    padding: 0 15px 0 15px;
    background: #f9cbb794 !important;
    border-radius: 6px;
}



.pie-chart-outer{
    background: white;
    border-radius: 7px 7px 0px 0px;
    border: 1px solid #d7dad7;
}
.detail-outer{
    display: flex;
    justify-content: space-between;
}
.reflection_heading{
    min-width: 132px;
}
.detail-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.8;
    margin-left: 13px;
    font-size: 12px;
}
.detail-heading {
    font-size: 12px;
    font-weight: 500;
}
.pie-chart-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 7px;
}
.survey-details {
    background: white;
    padding: 0px 7px 7px 7px;
}
.answer-details-question {
    display: flex;
    align-items: flex-start;
}
.question-number {
    margin-right: 12px;
}
.answer-details-outer {
    margin: 0px;
}
.section-dev .answer-details-outer{
    margin: 18px;
}
.previousResult .question-text {
    word-break: break-word;
    font-weight: 500;
}
.previousResult .answer-details-options {
    word-break: break-word;
}
.previousResult .answer-details-outer {
    padding: 10px 10px 23px;
    margin-top: -2px;
    /*margin-bottom: 18px;*/
    border: 1px solid #d7dad7;
    background: white;
    border-radius: 7px;
    width: 100%;
    min-height: 200px;
    box-shadow: 1px 2px 10px #b1b1b17a;
}
.options-list-heading .previousResult .answer-details-outer{
    margin-bottom:18px ;
    padding: 10px;
}
.previousResult .question-details-heading{
    font-size: 19px;
}

.previousResult .answer-details-option-outer {
    border-radius: 4px;
    padding: 8px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.previousResult .answer-details {
    margin-top: 12px;
    padding: 7px;
    background: white;
    border: none;
}

.answer-details-option-value {
    display: flex;
    min-width: 160px;
    align-items: center;
    justify-content: start;
}
.previousResult .option-value-icon {
    border-radius: 7px;
    display: inline-block;
    height: 25px;
    margin-right: 12px;
    max-width: 6px;
    min-width: 5px;
    margin-left: 18px;
}
.option-value-icon {
    border-radius: 7px;
    display: inline-block;
    height: 10px;
    margin-right: 12px;
    width: 20px;
    margin-left: 18px;
}
.selected-option {
    background: #f9cbb794 !important;
}
.result-card-body{
    background: white;
    text-align: center;
    font-family: "General Sans Medium";

}
.question-details-heading{
    font-size: 22px;
}
.question-details:hover {
    cursor: pointer;
    background: #f9f9f7;
}
.answer-details-option-outer {
    border-radius: 4px;
    padding: 7px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.answer-details {
    margin-top: -8px;
    padding: 7px;
    border: 1px solid #d7dad7;
    background: white;
    border-radius: 2px;
}
.question-details {
    background: white;
    display: flex;
    padding: 15px 25px 15px 25px;
    border: 1px solid #d7dad7;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
}
.pie-chart canvas {
    height: 100% !important;
    max-width: 100% !important;
}
.bg-grey{
    background: #c0b7b780;
}

.risk {
    margin: 2px;
    /*background: aliceblue;*/
    display: flex;
    height: 40px;
    min-width: 132px;
}
.text{
    font-family: "General Sans Regular";
}
.pie-chart-details {
    margin-left: 28px;
}
.result.mediumFontSize {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
}
.text-color-good{
    color: green;
}
.text-color-low-risk{
    color: #ff7100;
}
.text-color-high-risk{
    color: red;
}
.percentage{
    opacity: .7;
    font-size: 14px;
    font-family: "General Sans Regular";
}
.risk-bar {
    height: 40px;
    width: 8px;
    margin-right: 20px;
    border-radius: 20px;
}
.bg-red{
    background: red;
}
.bg-orange{
    background: #ff7100;
}
.bg-green{
    background: green;
}
.survey-detail-heading {
    font-family: "General Sans Regular";
    border-radius: 7px 7px 0 0;
    background: white;
    padding: 12px 0px 11px 11px;
    margin: -1px;
    border-bottom: 1px solid #e2e5e0;
    border-top: 1px solid #e2e5e0;
    border-left: 1px solid #e2e5e0;
    border-right: 1px solid #e2e5e0;
}





@media screen and (max-width:600px) {


    .pie-chart-container {
        display: block;
    }
    .pie-chart-details {
        /*display: flex;*/
        /*align-items: center;*/
        /*justify-content: space-evenly;*/
        margin-left: 0px !important;
        margin-top: 20px;
    }
    .risk-bar {
        margin-right: 10px;
    }
    .risk{
        width: 100%;
    }
    .pie-chart canvas{
        margin: auto;
    }

}

@media screen and (max-width:550px) {


    .pie-chart-container {
        display: block;
    }
    .pie-chart-details {
        display: block;
        margin-left: 0px !important;
        margin-top: 20px;
    }
    .risk {
        width: 100%;
    }
    .risk-bar {
        margin-right: 20px;
    }
    .pie-chart canvas{
        margin: auto;
    }

}
@media screen and (max-width:350px) {


    .result.mediumFontSize {
        font-size: 14px;
    }

}
