.roleName_heading {
    font-size: 18px;
    font-weight: 400
}

/*.update_role_container {*/
/*    background: rgba(240, 239, 235, 1);*/
/*}*/

.update_role_outer {
    border-radius: 0 0 8px 8px;
    background: white;
    padding: 0 15px 15px 15px;
    border: 1px solid #e2e5e0;
    border-top: none;
}



.btn_containerBox {
    display: flex;
    justify-content: start;
}

table.permission_table {
    border-collapse: separate;
    border-spacing: 0;
}

.permission_table td, .permission_table th {
    border-top: 1px solid #ddd;
    padding: 10px 31px;
    border-bottom: 1px solid #ddd;
    font-weight: 400;

}


.update-role-heading {
    border-radius: 8px 8px 0 0;
    font-size: 20px;
    font-weight: 450;
    padding-top: 8px;
    margin-bottom: 0 !important;
    padding-bottom: 7px !important;
    border: 1px solid #e2e5e0;
    background: white;
}



.permission_table tr td:first-child, .permission_table tr th:first-child {
    border-top-left-radius: 10px;
    border-left: 1px solid #ddd;
}

.permission_table tr td:last-child, .permission_table tr th:last-child {
    border-top-right-radius: 10px;
    border-right: 1px solid #ddd;
}

.permission_table tr td:first-child, .permission_table tr th:first-child {
    border-bottom-left-radius: 10px;
}

.permission_table tr td:last-child, .permission_table tr th:last-child {
    border-bottom-right-radius: 10px;
}


.cancel_btn {
    margin-right: 7px;
}
.update_btn {
    margin-left: 7px;
}


.page-content {
    display: flex;
}
