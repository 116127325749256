.pulse-logo {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #f2f4f7;
    padding-left: 44px;
    justify-content: space-between;
}
.pulse-logo img {
    width: 90px;
}
.profile-log-out-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.log-out-arrow{
    height: 15px;
    width: 20px !important;
    margin-bottom: 3px;
}
.profile-log-out-btn .placeholder-container {
    height: 40px;
    width: 40px;
    margin-right: 25px;
    font-size: 12px !important;
}
.plans-section {
    padding-top: 60px;
    background: linear-gradient(to bottom, #fab9a836, white);
}
.plans-body{
    width: 80%;
    margin: auto;
    text-align: center;
    overflow: hidden;
    word-break: break-word;
}
.choose-plan-heading{
    color: #222;
    text-align: center;
    font-family: Generalsans Medium,sans-serif;
    font-size: 45px;
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 700;
    line-height: 1.222em;
}
.tag-line{
    font-weight: 300;
    text-align: center;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    color: #171919;
}
.plan-buttons {
    padding: 7px;
    display: flex;
    width: 310px;
    background: white;
    margin: auto;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 120px;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 30px;
}
.monthly-plan-button {
    padding: 14px 24px;
    font-weight: 349;
    font-size: 16px;
    cursor: pointer;
}
.annual-plan-button {
    padding: 14px 24px;
    font-weight: 349;
    font-size: 16px;
    cursor: pointer;
}
.selected-plan {
    font-size: 18px;
    padding: 12px 22px;
    background: linear-gradient(#db4c2d,#fcad80);
    border-radius: 120px;
    color: white;
}

.monthly-plan-button:hover  {
    color: #fff;
    background-color: #fcad80;
    border-color: #fcad80;
    border-radius: 120px;
}
.annual-plan-button:hover  {
    color: #fff;
    background-color: #fcad80;
    border-color: #fcad80;
    border-radius: 120px;
}
.plans-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 980px;
    margin: auto;
}
.plans {
    text-align: start;
    border: 1px solid #eaecf0;
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px -2px rgba(16,24,40,.03);
}
.price {
    font-size: 16px;
    font-weight: 400;
}
.plan-buttons-loader {
    max-width: 320px;
    height: 60px;
    border-radius: 140px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.plan-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #101828;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Generalsans,sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
}
.pulse-email{
    color: royalblue;
    /*color: #f06212;*/
}
.vertical-divider {
    display: inline-block;
    width: 100%;
    margin: auto;
    height: 1px;
    background: #eaecf0;
    margin-top: 15px;
    margin-bottom: 15px;
}
.invoice-download-link{
    cursor: pointer;
    color: #f06212;
    text-decoration: underline;
}
.plan-spec-heading {
    font-family: Generalsans Medium,sans-serif;
    color: #e26340;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}
.check-circle {
    fill: #039854;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.plan-spec {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.specification {
    font-weight: 349;
    color: #676767;
    letter-spacing: normal;
    font-family: Generalsans,sans-serif;
    font-size: 15px;
    line-height: 1.5;
}
.get-started-btn-outer{
    margin-top: 20px;
    text-align: center;
}
button.get-started-btn {
    height: 50px;
    background: white;
    border-radius: 30px;
    width: 90%;
    color: #db4c2d;
    border: 2px solid #db4c2d;
}
button.get-started-btn:hover{
    color: #fff;
    background-image: linear-gradient(#fcad80,#db4c2d);
    border-color: #fff;
}
.popular-text {
    color: #e26340;
    padding: 4px 4px 0px 4px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    height: 25px;
    width: 100px;
    background: rgba(244,148,106,.1);
    text-align: center;
    border: 1px solid #e26340;
}
.plan-buttons-upgrade{
    margin-top: 10px;
    margin-bottom: 20px;
}
.tag-line-upgrade{
    width: 100%;
}
.choose-plan-heading-upgrade {
    font-size: 40px;
    margin-bottom: 5px;
}
.upgrade-plan-modal{
    margin-top: 0px !important;
}
.individual-plan-upgrade {
    margin-bottom: 10px !important;
}
.individual-plan{
    background: white;
}
.plans-section-upgrade {
    padding-top: 20px;
}
.upgrade-modal-body{
    background: white;
    border-radius: 20px !important;
}
@media screen and (max-width: 1199px) {
    .plans{
        padding: 1rem;
    }
    .popular-text {
        padding: 2px 2px 0px 2px;
        font-size: 10px;
        height: 20px;
        width: 80px;
    }
    button.get-started-btn {
        height: 40px;
        width: 80%;
    }
    .plan-heading{
        font-size: .8rem;
    }
    .vertical-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .plan-spec-heading {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .specification {
        font-size: .7rem;
    }
    .check-circle {
        width: 15px;
        height: 15px;
    }
    .plans-outer {
        justify-content: space-around;
    }
}
@media screen and (max-width: 856px) {
    .plans-body{
        width: 90%;
    }
}
@media screen and (max-width: 767px) {
    .plans-body{
        width: 100%;
    }
    .popular-text {
        padding: 4px 4px 0px 4px;
        font-size: 12px;
        height: 25px;
        width: 100px;
    }
    button.get-started-btn {
        height: 50px;
        width: 90%;
    }
    .plans-outer {
        display: block;
    }
    .plans {
        padding: 1.5rem;
        margin: auto;
        max-width: 370px;
        margin-bottom: 20px;
    }
    .plan-heading{
        font-size: 1.4rem;
    }
    .vertical-divider {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .plan-spec-heading {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .specification {
        font-size: 15px;
    }
    .check-circle {
        width: 20px;
        height: 20px;
    }
}
@media screen and (max-width: 400px) {
    .plan-buttons{
        width: 100%;
    }
    .pulse-logo {
        padding-left: 20px;
    }
    .profile-log-out-btn .placeholder-container {
        margin-right: 20px;
    }
}
@media screen and (max-width: 285px) {
    .plan-buttons{
        display: block;
        border-radius: 30px;
    }
    span.cursor-pointer.me-4.log-out-btn.mediumFontSize {
        margin-right: 10px !important;
    }
}
@media screen and (max-width: 260px) {
    .pulse-logo{
        display: block;
        text-align: center;
        margin-top: 10px;
        height: 100px;
    }
    .profile-log-out-btn {
        justify-content: center;
        margin-top: 20px;
    }
}
@media screen and (max-width: 280px) {
    .plan-heading{
        display: block;
    }
    .popular-text {
        margin-top: 5px;
    }
}