.modal-title {

    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #171919;

}

.result_modal_data {
    background: #fff;
    border-radius: 12px;
}
.reset-password-form-input::placeholder {
    opacity: .5 !important;
}
.result_modal_data .content {
    display: flex;
    padding: 9px 0px 2px;
}

.result_modal_data .content h5 {
    font-size: 15px;
    margin-top: 5px;
}

.result_modal_data .content p {
    margin-left: auto;
    margin-right: 12px;
    font-size: 14px;

}

.closeImg {
    border: 2px solid #e2e2e2;
    width: 22px;
    height: 22px;
    border-radius: 6px;
    margin-left: auto;
}

.closeImg img {
    width: 10px;
    margin-left: 4px;
    margin-top: -9px;
}

/*.reset_form input::placeholder {*/
/*    font-family: 'General Sans Bold';*/
/*    font-style: normal;*/
/*    font-size: 14px;*/
/*    letter-spacing: -0.03em;*/
/*    color: #171919;*/
/*}*/

.modal_outer {
    background: #fff;

}

.reset_form {
    background: #fff;
    border-radius: 4px;
    padding: 10px;
}

.modal_footer {
    background: #fff;
    border: transparent;
    padding-top: 0px;
}

.label {
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #404240;
}
.modal-header {
    border: transparent;
    padding-bottom: 0px;
}
button.close {
    border: transparent;
}

.mediumFontSize.repeat_pass.modal-title.h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
}
.relative_path i {
    position: absolute;
    right: 10px;
    top: 41px;
}

.relative_path input {
    padding-right: 40px;
}