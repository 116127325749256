.main-header {
        /*height: 60px;*/
        /*display: flex;*/
        /*background: white;*/
        /*box-shadow: 0 0 5px rgba(0, 0, 0, 20%);*/
        /*position: fixed;*/
        /*z-index: 999;*/
        /*width: 100%;*/
    padding-top: 10px;
    padding-bottom: 25px;
}
.user_info .placeholder-container {
    height: 38px;
    width: 38px;
    font-size: 12px !important;
    border-radius: 10px !important;
}
.user_info .placeholder-container:hover {
    box-shadow: 1px 1px 4px #797575;
}
svg.hamburger-icon {
    height: 25px;
    width: 25px;
    color: black;
    margin-right: -1px;
}
.image_display {
    width: 100%;
}
.hamburger-icon-outer{
    display: none;
    margin-top: 15px;
    margin-left: 35px;
}
.image_display img {
    /*width: 100%;*/
    width: 90px;
}
.close-hamburger{
    margin-left: 3px;
    margin-right: 4px;
    height: 17px;
    width: 17px;
}
.user_info {
    margin-left: auto;
    display: flex;
}

.user_info img {
    width: 40px;
    height: 40px;
    margin-top: 10px;
}
.add-reflection-btn {
    padding: 8px 12px;
    border-radius: 10px;
    border: transparent;
    font-size: 14px;
}
.add-reflection-btn-outer {
    margin-right: 8px;
}
.user_stuff {
    margin-right: 43px;
    margin-left: 9px;
}

.user_stuff h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 47px;
    height: 11px;
}

.org-list-dropdown-outer{
    margin-top: 10px;
    margin-right: 17px;
}
.user_stuff p {
    height: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 46px;
}
.org-list-dropdown .react-select__control {
    border-radius: 17px;
}
.org-list-select.react-select__control.css-13cymwt-control {
    min-height: 42px !important;
}
.list_data li {
    color: #171919;
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.image_display img.custom-logo {
    width: 34px;
    height: 34px;
}
/*media query starts here*/
@media screen and (max-width:767px){

    .hamburger-icon-outer {
        display: block;
        margin-top: 15px;
        margin-left: 5px;
    }
    .add-reflection-btn-outer {
        min-width: 140px;
        margin-top: 14px;
        margin-right: 17px;
    }
    .add-reflection-btn {
        padding: 4px 17px;
        min-width: 150px;
        border-radius: 8px;
        border: transparent;;
    }

    .image_display{
        margin-left: 15px;
    }
}

@media screen and (max-width:550px){


    svg.hamburger-icon {
        height: 25px;
        width: 22px;
        color: black;
        margin-right: -7px;
    }
    .add-reflection-btn-outer {
        margin-top: 18px;
        margin-right: 17px;
        margin-left: 24px;
        font-size: 15px;
    }
    .add-reflection-btn {
        padding: 4px 7px 6px 5px;
        min-width: 150px;
        border-radius: 8px;
        border: transparent;
    }
}

