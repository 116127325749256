.add-reflection-window-outer {
    min-width: 350px;
    position: absolute;
    top: 54px;
    padding: 20px;
    right: 16px;
    width: 350px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.15);
    z-index: 99;
}

.add-reflection-submit-btn {
    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
    border-radius: 10px;
    height: 38px;
    border: none;
    line-height: 24px;
    font-weight: 600;
    color: #ffff;
    font-family: "General Sans Regular";
    font-size: 14px;
    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
}
.choose-mentee-txt {
    font-size: 12px;
    font-family: 'General Sans Medium';
}
.add-reflection-window-outer select {
    font-size: 14px;
}

h2.mediumFontSize.add-reflection-txt {
    font-size: 18px;
    font-weight: 100;
    font-weight: 500;
    font-family: "Inter";
}

.add-reflection-window-select:focus {
    border-color: #d7dad7;
    outline: none;
    box-shadow: none;
}
@media screen and (max-width:768px){
    .add-reflection-window-outer {
        right: 12px;
    }
    .add-reflection-btn-outer{
        margin-right: 10px;
    }
}
@media screen and (max-width:390px){
    .add-reflection-window-outer {
        min-width: 92%;
        width:unset
    }
}