.signup_success.cmn_outer {
    text-align: center;
    padding: 0;
}

.success_content p {
    margin: 15px 0;
}
.signup_success .log_image img {
    width: 100px;
    margin: auto;
    opacity: 0.5;
}
.react-tel-input .country-list{
    height: 108px;
}

.sub-org-container-box {
    background: white;
    padding: 0px 25px;
    border-radius: 20px;
    margin-top: 15px;
    border: 1px solid #e2e2e2;
}
.react-tel-input .form-control{
    height: 38px;
    width: 100% !important;
}
.active {
    background: #F0EFEB !important;
    height: 40px !important;;
    width: 100% !important;;
}
.success_image {
    background: linear-gradient(214.03deg, #FCAD80 3.66%, #DB4C2D 90.58%);
    padding: 30px 15px;
}
.truncate-select {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.success_content {
    padding: 30px;
}

.log_image{
    padding-top: 30px;
    padding-bottom: 10px;
}

.log_redirect{
    padding: 10px;

}
.signup_success .cmn_btn:hover {
    color: #fff;
}

.signup_success .cmn_btn{
display: inline-block;
    line-height: 56px;
    text-decoration: unset;
}