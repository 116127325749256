.user-profile-email{
    text-align: center;
    word-break: break-all;
}

.user-profile-window-profile-placeholder-outer .placeholder-container{
    height: 70px;
    width: 70px;
    margin: auto;
    font-size: 20px !important;
}
.hi-user-txt {
    font-size: 22px;
    text-align: center;
}

.switch-role-btn {
    border-radius: 40px;
    min-height: 50px;
    padding-top: 3px;
    padding-bottom: 3px;
    line-height: 24px;
    border: none;;
    color: white;
    width: 90%;
}
.logout-icon{
    height: 18px;
    width: 18px;
}
.log-out-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 40px;
    min-height: 50px;
    padding-top: 3px;
    padding-bottom: 3px;
    line-height: 24px;
    border: 1px solid #ccc7c7ad;
    color: black;
    background: white;
    width: 90%;
}