
.login_heading{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #171919;;
    }
    .forgetpass{
        color: #9C9F9C;;  
     font-style: normal;
      font-weight: 600;
     font-size: 14px;
           line-height: 20px;
        text-decoration: none;
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
    
    }
    .forgetpass:hover {
        color: #9C9F9C;
    }

    .pulse_img{
        opacity: 0.5;
    }
    
    .btn_outer{
        padding-top: 30px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .form_outer{
        padding-top: 30px;
    }
    .password_icon_outer {
        position: absolute;
        top: 5px;
        left: auto;
        right: 15px;
    }
    .password_icon_outer:hover{
        cursor: pointer;
    }

    
    .password_container {
        position: relative;
    }
    .cmn_form_outer label{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #404240;  
       
    }
    .cmn_form_outer{
        padding-top: 36px;
     
    }
.cmn_form_outer .reflection-type-dropdown{
height: 38px;
}
    .container_box{
        display: flex;
         align-items: center;
         min-height: 100vh;
        justify-content: center;
        background: #cfcfd0;
    }
    /* media query for large screen */
    /* media query for tablet */
    @media screen and (max-width:768px){
        .cmn_outer{
            width:530px;
            margin: auto;
        }
        
    }
    @media screen and (min-width:320px) and (max-width:767px){
        .cmn_heading{
            font-size: 24px;
        }
    }